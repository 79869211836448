import {
  AudioMutedOutlined,
  AudioOutlined,
  LoadingOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Report, ReportProcessing } from "@thiana/api-thiana-client";
import { Button, Flex, Input, Typography, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  apiProcessings,
  apiReports,
} from "../../../../../../api-configuration/Configuration";
import Context from "../../../../../../context/Context";
import useLLM from "../../../../../../hooks/useLLM";
import useReports from "../../../../../../hooks/useReports";
import { emptyReport } from "../../../../../../types/Reports";
import { DEFAULT_TEMPLATE_UUID } from "../../../../../../utils/constants";
import { isAIAllowed } from "../../../../../../utils/security";
import VoiceRecognitionAPI from "../../../../common/voice-recognition/VoiceRecognitionAPI";
import useTypeWriter from "../../../../../../hooks/useTypeWriter";
import { TranscriptionObject } from "../../../../../../types/AI/VoiceRecognition";
import thiana_logo_head from "../../../../../../assets/images/logo/LOGO_HEAD.svg";

interface Props {
  SOCKET_ASR: React.MutableRefObject<WebSocket | undefined>;
}

export default function Commands(props: Props) {
  const [commands, setCommands] = useState<ReportProcessing[]>([]);
  const [textToDisplay, setTextToDisplay] = useState<string>("");
  const [transcriptionObject, setTranscriptionObject] = useState<
    TranscriptionObject | undefined
  >(undefined);

  const params = useParams();
  const { autoCreateReport, createReport, fetchReportByID } = useReports({});

  const navigate = useNavigate();

  const { typeWriter, displayText, resetTypeWriter } = useTypeWriter();

  const {
    currentReport,
    currentCommand,
    updateCurrentCommand,
    updateCurrentReport,
    theme,
    isASRProcessing,
    updateIsASRProcessing,
    voiceRecognitionAPI,
    dispatchFlow,
    flowState,
  } = useContext(Context);

  const { startGeneration } = useLLM();

  useEffect(() => {
    (async () => {
      if (params.reportID) {
        let report = await fetchReportByID(params.reportID);
        if (report) setCommands(report.processings as ReportProcessing[]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.reportID]);

  useEffect(() => {
    if (transcriptionObject) {
      typeWriter(transcriptionObject.transcription, setTextToDisplay);
    }
  }, [transcriptionObject]);

  const handleTranscription = () => {
    if (!isASRProcessing) {
      updateIsASRProcessing(true);
      voiceRecognitionAPI?.startTranscription((data) => {
        if (data.state !== "closing") setTranscriptionObject(data);
        else {
          console.log("final_update", transcriptionObject);
          updateCurrentCommand(currentCommand + " " + data.transcription);
          dispatchFlow({ type: "SOCKET_ASR_STOP" });
          resetTypeWriter();
        }
      });
    } else {
      voiceRecognitionAPI?.stopTranscription(() => {
        dispatchFlow({ type: "COMMAND_PAUSE_ASR" });
        updateIsASRProcessing(false);
      });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateCurrentCommand(e.target.value);
  };

  const handleKeyPress = async (e: any) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent default Enter behavior (new line)
      await handleSubmit();
    }
  };

  const handleSubmit = async () => {
    let reportID = params.reportID;
    let reportToUse: Report = emptyReport;
    if (reportID === undefined) {
      await apiReports
        .reportCreate({
          reportInput: {
            generation: currentReport?.generation,
            transcription: "",
            title: "",
            report_template_id: DEFAULT_TEMPLATE_UUID,
          },
        })
        .then(async (data: any) => {
          // console.log(data.data[0]);
          reportID = data.data[0].id;
          reportToUse = data.data[0];
          // console.log("data.data[0]", data.data[0]);
          updateCurrentReport(data.data[0]);
          navigate("/documents/" + data.data[0].id);
        });
    } else {
      reportToUse = currentReport as Report;
    }

    // console.log("reportToUse", reportToUse);
    // console.log("currentCommand", currentCommand);
    let response = await apiProcessings.processingReportCreateRaw({
      reportProcessingInput: {
        content: currentCommand,
        report_id: reportToUse.id,
      },
    });
    if (response.raw.status === 201) {
      let data = await response.raw.json();
      // console.log(data.data[0]);
      console.log("commands", commands);
      let newCommands = [...commands, data.data[0]];
      setCommands(newCommands);
      console.log("reportToUse", reportToUse);
      startGeneration(reportToUse, currentCommand, "command", false);
    }

    updateCurrentCommand(""); // Clear input after submission
  };

  return (
    <Flex
      vertical
      justify="space-between"
      style={{
        boxSizing: "border-box",
        // height: "calc(100vh - 138px)",
        height: "100%",
      }}
    >
      <Flex
        vertical
        flex={1}
        style={{
          overflowY: "scroll",
          boxSizing: "border-box",
        }}
      >
        {" "}
        {commands.length > 0 ? (
          commands.map((item: ReportProcessing, index: number) => {
            return (
              <div
                style={{
                  marginTop: 16,
                  marginRight: 16,
                  marginLeft: 16,
                  boxSizing: "border-box",
                }}
                key={index}
              >
                <Typography>{item.content}</Typography>
                <Typography style={{ fontSize: 10, opacity: 0.4 }}>
                  Le {item.created_at}
                </Typography>
              </div>
            );
          })
        ) : (
          <Flex vertical flex={1} align="center" justify="center">
            <img
              alt="Logo thiana"
              src={thiana_logo_head}
              style={{ height: 128 }}
            />
            <Typography.Title level={4} style={{ textAlign: "center" }}>
              Dites moi ici comment je dois <br />
              modifier votre votre compte rendu.
            </Typography.Title>
          </Flex>
        )}
      </Flex>
      <Flex
        vertical
        style={{
          boxSizing: "border-box",
          width: "100%",
        }}
      >
        {/* <form
          // style={{ height: "10%", maxHeight: "10%" }}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        > */}
        <Flex
          gap={1}
          style={{
            alignItems: "center",
            padding: 16,
            borderRadius: 16,
            backgroundColor:
              theme === "light" ? "rgba(0,0,0,0.1)" : "rgba(255,255,255,0.1)",
            border: 8,
          }}
        >
          <div
            style={{
              width: "100%",
              padding: 8,
              maxHeight: 10 * 16,
              minHeight: 2 * 16,
              overflowY: "scroll",
            }}
          >
            {flowState.currentState === "WAITING_FOR_LAST_ASR_FEEDBACK" ||
            flowState.currentState === "RECORDING" ||
            isASRProcessing ? (
              displayText(
                currentCommand,
                textToDisplay,
                transcriptionObject as TranscriptionObject
              )
            ) : (
              <Input.TextArea
                value={currentCommand}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
                disabled={isASRProcessing}
                autoSize={{ minRows: 2, maxRows: 10 }}
                placeholder="Dites à Thiana ce qu'elle doit modifier dans votre compte rendu..."
                style={{
                  padding: 8,
                  border: "none",
                  background: "transparent",
                  fontSize: "16px",
                  lineHeight: "16px",
                }}
                onMouseOver={(e) => {
                  (e.target as HTMLTextAreaElement).style.borderColor =
                    "transparent";
                  (e.target as HTMLTextAreaElement).style.boxShadow = "none";
                }}
              />
            )}
          </div>
          <Tooltip
            title={
              !isAIAllowed()
                ? "Un abonnement est nécessaire pour utiliser la reconnaissance vocale."
                : null
            }
          >
            <Button
              icon={
                flowState.currentState === "WAITING_FOR_LAST_ASR_FEEDBACK" ? (
                  <LoadingOutlined />
                ) : flowState.currentState === "RECORDING" ? (
                  <AudioMutedOutlined />
                ) : (
                  <AudioOutlined />
                )
              }
              danger={isASRProcessing}
              type="text"
              size="large"
              disabled={!isAIAllowed()}
              onClick={handleTranscription}
            />
          </Tooltip>
          <Tooltip
            title={
              !isAIAllowed()
                ? "Un abonnement est nécessaire pour utiliser l'IA."
                : null
            }
          >
            <Button
              htmlType="submit"
              icon={<SendOutlined />}
              onClick={async () => await handleSubmit()}
              type="text"
              disabled={currentCommand === "" || !isAIAllowed()}
              size="large"
            />
          </Tooltip>
        </Flex>
        {/* </form> */}
      </Flex>
      {/* <div style={{ height: "90%", maxHeight: "90%", overflowY: "auto" }}>
        {commands.map((item: ReportProcessing, index: number) => {
          return (
            <div
              style={{
                minHeight: 70,
              }}
              key={index}
            >
              <Typography>{item.content}</Typography>
              <Typography style={{ fontSize: 10, opacity: 0.4 }}>
                Le {item.created_at}
              </Typography>
            </div>
          );
        })}
      </div> */}
    </Flex>
  );
}
