"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeInputToJSON = exports.NodeInputFromJSONTyped = exports.NodeInputFromJSON = exports.instanceOfNodeInput = exports.NodeInputActionEnum = void 0;
/**
 * @export
 */
exports.NodeInputActionEnum = {
    Truefalse: 'truefalse',
    Edit: 'edit',
    Extract: 'extract'
};
/**
 * Check if a given object implements the NodeInput interface.
 */
function instanceOfNodeInput(value) {
    if (!('action' in value) || value['action'] === undefined)
        return false;
    if (!('input' in value) || value['input'] === undefined)
        return false;
    if (!('instruction' in value) || value['instruction'] === undefined)
        return false;
    return true;
}
exports.instanceOfNodeInput = instanceOfNodeInput;
function NodeInputFromJSON(json) {
    return NodeInputFromJSONTyped(json, false);
}
exports.NodeInputFromJSON = NodeInputFromJSON;
function NodeInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'action': json['action'],
        'html_content': json['html_content'] == null ? undefined : json['html_content'],
        'input': json['input'],
        'instruction': json['instruction'],
        'raw_content': json['raw_content'] == null ? undefined : json['raw_content'],
    };
}
exports.NodeInputFromJSONTyped = NodeInputFromJSONTyped;
function NodeInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'action': value['action'],
        'html_content': value['html_content'],
        'input': value['input'],
        'instruction': value['instruction'],
        'raw_content': value['raw_content'],
    };
}
exports.NodeInputToJSON = NodeInputToJSON;
