"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatbotStreamItemToJSON = exports.ChatbotStreamItemFromJSONTyped = exports.ChatbotStreamItemFromJSON = exports.instanceOfChatbotStreamItem = exports.ChatbotStreamItemRoleEnum = void 0;
/**
 * @export
 */
exports.ChatbotStreamItemRoleEnum = {
    User: 'user',
    Chatbot: 'chatbot'
};
/**
 * Check if a given object implements the ChatbotStreamItem interface.
 */
function instanceOfChatbotStreamItem(value) {
    if (!('content' in value) || value['content'] === undefined)
        return false;
    if (!('role' in value) || value['role'] === undefined)
        return false;
    return true;
}
exports.instanceOfChatbotStreamItem = instanceOfChatbotStreamItem;
function ChatbotStreamItemFromJSON(json) {
    return ChatbotStreamItemFromJSONTyped(json, false);
}
exports.ChatbotStreamItemFromJSON = ChatbotStreamItemFromJSON;
function ChatbotStreamItemFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'content': json['content'],
        'role': json['role'],
    };
}
exports.ChatbotStreamItemFromJSONTyped = ChatbotStreamItemFromJSONTyped;
function ChatbotStreamItemToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'content': value['content'],
        'role': value['role'],
    };
}
exports.ChatbotStreamItemToJSON = ChatbotStreamItemToJSON;
