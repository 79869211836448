import React, { useContext, useEffect, useRef, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { useParams } from "react-router-dom";
import useReports from "../../../../../hooks/useReports";
import Context from "../../../../../context/Context";
import { Report, ReportOutput } from "@thiana/api-thiana-client";
import { html } from "js-beautify";

import { apiReports } from "../../../../../api-configuration/Configuration";
import {
  $getRoot,
  $insertNodes,
  LexicalEditor,
  ParagraphNode,
  RootNode,
  $createParagraphNode,
  $createTextNode,
  LexicalNode,
  TextNode,
  ElementFormatType,
} from "lexical";
import {
  $createListNode,
  ListNode,
  ListItemNode,
  $createListItemNode,
} from "@lexical/list";
import { getTemplateById, putReport } from "../../../../../api/api";
import { emptyReport } from "../../../../../types/Reports";
import { Template, TemplatesResponse } from "../../../../../types/Templates";
import { DEFAULT_TEMPLATE_UUID } from "../../../../../utils/constants";

export default function CustomOnChangePlugin() {
  const [currentReportHTMLString, setCurrentReportHTMLString] =
    useState<string>("");
  const [currentTemplateHTMLString, setCurrentTemplateHTMLString] =
    useState<string>("");
  const initialized = useRef(false);
  const [editor] = useLexicalComposerContext();
  const params = useParams();
  const { autoSaveReport, autoCreateReport } = useReports({});
  const {
    currentReport,
    updateIsTemplateSaved,
    currentTemplate,
    updateCurrentTemplate,
    isLLMProcessing,
  } = useContext(Context);

  const customGenerateNodesFromDOM = (body: HTMLElement) => {
    // console.log("body", body);
    let lexicalNodesToAppend: LexicalNode[] = [];

    if (!body.hasChildNodes()) return lexicalNodesToAppend;

    Array.from(body.childNodes).map((baseNode) => {
      if (baseNode.nodeName === "P") {
        const paragraphNode: ParagraphNode = $createParagraphNode();
        const style = (baseNode as HTMLElement).getAttribute("style");
        if (style !== null) {
          let match = style.match(/text-align:\s*(center|right|left|justify);/);
          if (match) paragraphNode.setFormat(match[1] as ElementFormatType);
        }
        if (baseNode.hasChildNodes()) {
          Array.from(baseNode.childNodes).map((childNode) => {
            const textNode: TextNode = $createTextNode();
            parseChildNodes(childNode, textNode);
            paragraphNode.append(textNode);
          });
        }
        lexicalNodesToAppend.push(paragraphNode);
      } else if (baseNode.nodeName === "UL" || baseNode.nodeName === "OL") {
        const listNode: ListNode = $createListNode(
          baseNode.nodeName === "UL" ? "bullet" : "number"
        );
        if (baseNode.hasChildNodes()) {
          Array.from(baseNode.childNodes).map((childNode) => {
            if (childNode.nodeName === "LI") {
              const listItemNode: ListItemNode = $createListItemNode();
              if (childNode.hasChildNodes()) {
                Array.from(childNode.childNodes).map((grandChildNode) => {
                  const textNode: TextNode = $createTextNode();
                  parseChildNodes(grandChildNode, textNode);
                  listItemNode.append(textNode);
                });
              }
              listNode.append(listItemNode);
            }
          });
        }
        lexicalNodesToAppend.push(listNode);
      }
    });

    function parseChildNodes(childNode: ChildNode, textNode: TextNode) {
      const style = (childNode.parentElement as HTMLElement).getAttribute(
        "style"
      );
      if (style) textNode.setStyle(style);
      switch (childNode.nodeName) {
        case "U":
          if (!textNode.hasFormat("underline")) {
            textNode.toggleFormat("underline");
          }
          break;
        case "B":
          if (!textNode.hasFormat("bold")) {
            textNode.toggleFormat("bold");
          }
          break;
        case "I":
          if (!textNode.hasFormat("italic")) {
            textNode.toggleFormat("italic");
          }
          break;
        case "MARK":
          if (!textNode.hasFormat("highlight")) {
            textNode.toggleFormat("highlight");
          }
          break;
        case "SPAN":
          break;
        default:
          break;
      }
      if (childNode.hasChildNodes())
        Array.from(childNode.childNodes).map((childNode) => {
          parseChildNodes(childNode, textNode);
        });
      else {
        textNode.setTextContent(childNode.textContent as string);
      }
    }
    return lexicalNodesToAppend;
  };

  function containsHTML(str: string) {
    const regex = /<[^>]*>/;
    return regex.test(str);
  }

  const updateEditorFromString = (htmlString: string) => {
    editor.update(() => {
      const parser = new DOMParser();
      if (!containsHTML(htmlString)) {
        htmlString = "<p>" + htmlString.replace(/\r?\n/g, "</p><p>") + "</p>";
      }

      const dom = parser.parseFromString(htmlString, "text/html");
      const nodes = customGenerateNodesFromDOM(dom.body) as LexicalNode[];
      const root = $getRoot();
      root.clear();
      $insertNodes(nodes);
      editor.blur();
    });
  };

  useEffect(() => {
    if (window.location.pathname.includes("templates"))
      if (params.templateID) {
        if (currentTemplate !== undefined) {
          updateEditorFromString(currentTemplate.content);
        }
      }
    updateIsTemplateSaved(true);
  }, [currentTemplate?.id]);

  useEffect(() => {
    (async () => {
      // console.log("currentReport", currentReport);
      // console.log("currentReport?.id", currentReport?.id);
      // console.log(
      //   "currentReport?.report_template_id",
      //   currentReport?.report_template_id
      // );
      initialized.current = false;
      if (window.location.pathname.includes("documents")) {
        let templateStringToParse: string = "<body></body>";
        let templatesResponse: TemplatesResponse;
        let response: Response = await getTemplateById(DEFAULT_TEMPLATE_UUID);
        if (response.status === 200) {
          templatesResponse = await response.json();
          if (templatesResponse.data !== null)
            templateStringToParse = templatesResponse.data[0].content;
        }
        if (
          params.reportID === undefined &&
          window.location.pathname.includes("documents")
        )
          updateEditorFromString(currentReport?.generation as string);
        else updateEditorFromString(currentReport?.generation as string);
      }
    })();
  }, [currentReport?.id, currentReport?.report_template_id]);

  useEffect(() => {
    const updateListener = () => {
      editor.getEditorState().read(() => {
        const rawHtml = $generateHtmlFromNodes(editor, null);
        // console.log("rawHTML", rawHtml);
        const cleanedHtml = rawHtml
          .replace(/white-space: pre-wrap;/g, "")
          .replace(/ style=""/g, "")
          .replace(/ dir="ltr"/g, "")
          .replace(/ value="\d+"/g, "");
        const prettyHtml = html(cleanedHtml, { indent_size: 2 });
        // console.log("prettyHtml", prettyHtml);
        if (window.location.pathname.includes("documents")) {
          setCurrentReportHTMLString(prettyHtml);
        }
        if (window.location.pathname.includes("templates"))
          setCurrentTemplateHTMLString(prettyHtml);
      });
    };

    const removeUpdateListener = editor.registerUpdateListener(
      ({ editorState }) => updateListener()
    );

    return () => {
      removeUpdateListener();
    };
  }, [editor]);

  useEffect(() => {
    editor.setEditable(!isLLMProcessing);
  }, [isLLMProcessing]);

  useEffect(() => {
    if (isLLMProcessing) {
      updateEditorFromString(currentReport?.generation as string);
    }
  }, [currentReport?.generation]);

  useEffect(() => {
    // console.log("triggeredddd");
    // console.log(initialized.current);
    if (initialized.current) {
      if (params.reportID !== undefined && currentReport) {
        autoSaveReport({
          ...currentReport,
          generation: currentReportHTMLString,
        });
      } else if (params.reportID === undefined) {
        autoCreateReport({
          transcription: "",
          title: "",
          generation: currentReportHTMLString,
          report_template_id: DEFAULT_TEMPLATE_UUID,
        });
        // updateIsSaved({ isSaved: true });
      }
    } else
      setTimeout(() => {
        initialized.current = true;
      }, 1000);
  }, [currentReportHTMLString]);

  useEffect(() => {
    initialized.current = false;
    if (params.templateID === DEFAULT_TEMPLATE_UUID) editor.setEditable(false);
    else editor.setEditable(true);

    if (params.templateID === undefined) {
      editor.update(() => {
        const root = $getRoot();
        root.clear();
        editor.blur();
      });
      updateIsTemplateSaved(true);
    }
  }, [params.templateID]);

  useEffect(() => {
    if (initialized.current) {
      const updatedTemplate = {
        ...currentTemplate,
        content: currentTemplateHTMLString,
      };
      updateIsTemplateSaved(false);
      updateCurrentTemplate(updatedTemplate as Template);
    } else initialized.current = true;
  }, [currentTemplateHTMLString]);

  return null;
}
