import { Button, Flex, Switch, Typography, theme as antdTheme } from "antd";
import { PlusOutlined, UnorderedListOutlined } from "@ant-design/icons";
import useScreenSize from "../../../hooks/useScreensize";
import breakpoints from "../../../theme/antdesign";
import { useContext } from "react";
import Context from "../../../context/Context";
import useReports from "../../../hooks/useReports";
import LOGO from "../../../assets/images/logo/LOGO_HEAD.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop, faUser } from "@fortawesome/free-solid-svg-icons";

interface Props {
  SOCKET_ASR: React.MutableRefObject<WebSocket | undefined>;
}

export default function HeaderDocument(props: Props) {
  const { currentScreenSize } = useScreenSize();
  const { resetAppState } = useReports({});
  const {
    isMenuHidden,
    updateIsMenuHidden,
    isASRProcessing,
    teleconsultation,
    updateTeleconsultation,
    theme,
  } = useContext(Context);
  const { token } = antdTheme.useToken();

  return (
    <Flex align="center" flex={1}>
      <Flex align="center" gap="small" flex={2}>
        <div>
          {isMenuHidden ? (
            <Flex align="center" gap={"small"}>
              <Button
                icon={<UnorderedListOutlined />}
                onClick={() => updateIsMenuHidden(false)}
              />
              <Button
                icon={<PlusOutlined />}
                disabled={isASRProcessing}
                onClick={() => {
                  resetAppState();
                }}
              >
                {currentScreenSize > breakpoints.md ? "Nouveau document" : null}
              </Button>
            </Flex>
          ) : null}
        </div>
      </Flex>

      <Flex align="center" justify="center" gap={16} flex={3}>
        <Flex gap={4} align="center">
          <Typography.Text style={{ fontSize: 16 }}>
            <FontAwesomeIcon
              style={{ color: token.colorTextBase }}
              icon={faUser}
            />{" "}
            Consultation classique
          </Typography.Text>
        </Flex>
        <Switch value={teleconsultation} onChange={updateTeleconsultation} />
        <Flex gap={4} align="center">
          <Typography.Text style={{ fontSize: 16 }}>
            <FontAwesomeIcon
              style={{ color: token.colorTextBase }}
              icon={faDesktop}
            />{" "}
            Téléconsultation
          </Typography.Text>
        </Flex>
      </Flex>

      {currentScreenSize > breakpoints.md ? (
        <Flex align="center" justify="flex-end" gap={4} flex={2}>
          <img
            alt="Logo Thiana Assistant"
            style={{
              width: 48,
              height: "auto",
            }}
            src={LOGO}
          />
          <Typography.Text style={{ fontWeight: 800, fontSize: 42 }}>
            Thiana AI
          </Typography.Text>
        </Flex>
      ) : null}
    </Flex>
  );
}
