"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetadataToJSON = exports.MetadataFromJSONTyped = exports.MetadataFromJSON = exports.instanceOfMetadata = void 0;
/**
 * Check if a given object implements the Metadata interface.
 */
function instanceOfMetadata(value) {
    if (!('pages' in value) || value['pages'] === undefined)
        return false;
    if (!('total' in value) || value['total'] === undefined)
        return false;
    return true;
}
exports.instanceOfMetadata = instanceOfMetadata;
function MetadataFromJSON(json) {
    return MetadataFromJSONTyped(json, false);
}
exports.MetadataFromJSON = MetadataFromJSON;
function MetadataFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'pages': json['pages'],
        'total': json['total'],
    };
}
exports.MetadataFromJSONTyped = MetadataFromJSONTyped;
function MetadataToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'pages': value['pages'],
        'total': value['total'],
    };
}
exports.MetadataToJSON = MetadataToJSON;
