"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmbeddedSubscriptionToJSON = exports.EmbeddedSubscriptionFromJSONTyped = exports.EmbeddedSubscriptionFromJSON = exports.instanceOfEmbeddedSubscription = void 0;
var SubscriptionItem_1 = require("./SubscriptionItem");
/**
 * Check if a given object implements the EmbeddedSubscription interface.
 */
function instanceOfEmbeddedSubscription(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('items' in value) || value['items'] === undefined)
        return false;
    return true;
}
exports.instanceOfEmbeddedSubscription = instanceOfEmbeddedSubscription;
function EmbeddedSubscriptionFromJSON(json) {
    return EmbeddedSubscriptionFromJSONTyped(json, false);
}
exports.EmbeddedSubscriptionFromJSON = EmbeddedSubscriptionFromJSON;
function EmbeddedSubscriptionFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'items': (json['items'].map(SubscriptionItem_1.SubscriptionItemFromJSON)),
    };
}
exports.EmbeddedSubscriptionFromJSONTyped = EmbeddedSubscriptionFromJSONTyped;
function EmbeddedSubscriptionToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'items': (value['items'].map(SubscriptionItem_1.SubscriptionItemToJSON)),
    };
}
exports.EmbeddedSubscriptionToJSON = EmbeddedSubscriptionToJSON;
