"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConversationInputToJSON = exports.ConversationInputFromJSONTyped = exports.ConversationInputFromJSON = exports.instanceOfConversationInput = void 0;
/**
 * Check if a given object implements the ConversationInput interface.
 */
function instanceOfConversationInput(value) {
    if (!('content' in value) || value['content'] === undefined)
        return false;
    if (!('medical_record_id' in value) || value['medical_record_id'] === undefined)
        return false;
    if (!('title' in value) || value['title'] === undefined)
        return false;
    return true;
}
exports.instanceOfConversationInput = instanceOfConversationInput;
function ConversationInputFromJSON(json) {
    return ConversationInputFromJSONTyped(json, false);
}
exports.ConversationInputFromJSON = ConversationInputFromJSON;
function ConversationInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'content': json['content'],
        'medical_record_id': json['medical_record_id'],
        'title': json['title'],
    };
}
exports.ConversationInputFromJSONTyped = ConversationInputFromJSONTyped;
function ConversationInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'content': value['content'],
        'medical_record_id': value['medical_record_id'],
        'title': value['title'],
    };
}
exports.ConversationInputToJSON = ConversationInputToJSON;
