"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientInformationToJSON = exports.PatientInformationFromJSONTyped = exports.PatientInformationFromJSON = exports.instanceOfPatientInformation = void 0;
/**
 * Check if a given object implements the PatientInformation interface.
 */
function instanceOfPatientInformation(value) {
    if (!('birth_date' in value) || value['birth_date'] === undefined)
        return false;
    if (!('first_name' in value) || value['first_name'] === undefined)
        return false;
    if (!('last_name' in value) || value['last_name'] === undefined)
        return false;
    return true;
}
exports.instanceOfPatientInformation = instanceOfPatientInformation;
function PatientInformationFromJSON(json) {
    return PatientInformationFromJSONTyped(json, false);
}
exports.PatientInformationFromJSON = PatientInformationFromJSON;
function PatientInformationFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'birth_date': json['birth_date'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
    };
}
exports.PatientInformationFromJSONTyped = PatientInformationFromJSONTyped;
function PatientInformationToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'birth_date': value['birth_date'],
        'first_name': value['first_name'],
        'last_name': value['last_name'],
    };
}
exports.PatientInformationToJSON = PatientInformationToJSON;
