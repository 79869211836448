"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientToJSON = exports.PatientFromJSONTyped = exports.PatientFromJSON = exports.instanceOfPatient = void 0;
/**
 * Check if a given object implements the Patient interface.
 */
function instanceOfPatient(value) {
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('deleted_at' in value) || value['deleted_at'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('ssn' in value) || value['ssn'] === undefined)
        return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined)
        return false;
    if (!('user_id' in value) || value['user_id'] === undefined)
        return false;
    return true;
}
exports.instanceOfPatient = instanceOfPatient;
function PatientFromJSON(json) {
    return PatientFromJSONTyped(json, false);
}
exports.PatientFromJSON = PatientFromJSON;
function PatientFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'created_at': json['created_at'],
        'deleted_at': json['deleted_at'],
        'id': json['id'],
        'ssn': json['ssn'],
        'updated_at': json['updated_at'],
        'user_id': json['user_id'],
    };
}
exports.PatientFromJSONTyped = PatientFromJSONTyped;
function PatientToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'created_at': value['created_at'],
        'deleted_at': value['deleted_at'],
        'id': value['id'],
        'ssn': value['ssn'],
        'updated_at': value['updated_at'],
        'user_id': value['user_id'],
    };
}
exports.PatientToJSON = PatientToJSON;
