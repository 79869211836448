"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TranscriptionToJSON = exports.TranscriptionFromJSONTyped = exports.TranscriptionFromJSON = exports.instanceOfTranscription = void 0;
/**
 * Check if a given object implements the Transcription interface.
 */
function instanceOfTranscription(value) {
    if (!('end_at' in value) || value['end_at'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('report_id' in value) || value['report_id'] === undefined)
        return false;
    return true;
}
exports.instanceOfTranscription = instanceOfTranscription;
function TranscriptionFromJSON(json) {
    return TranscriptionFromJSONTyped(json, false);
}
exports.TranscriptionFromJSON = TranscriptionFromJSON;
function TranscriptionFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'end_at': json['end_at'],
        'id': json['id'],
        'report_id': json['report_id'],
    };
}
exports.TranscriptionFromJSONTyped = TranscriptionFromJSONTyped;
function TranscriptionToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'end_at': value['end_at'],
        'id': value['id'],
        'report_id': value['report_id'],
    };
}
exports.TranscriptionToJSON = TranscriptionToJSON;
