import { message } from "antd";
import { isChrome, isFirefox, isSafari } from "react-device-detect";
import {
  IMediaRecorder,
  MediaRecorder,
  register,
} from "extendable-media-recorder";
import { connect } from "extendable-media-recorder-wav-encoder";

(async () => {
  await register(await connect());
})();

// Fonction pour détecter le navigateur
const getNavigator = () => {
  if (isFirefox) return "firefox";
  else if (isSafari) return "safari";
  else if (isChrome) return "chrome";
  //@ts-ignore
  else if (navigator.brave) return "brave";
  else return "unknow";
};

// Classe AudioRecorder
export default class AudioRecorder {
  private audioContext: AudioContext | undefined;
  private micStream: MediaStream | undefined;
  private mediaRecorder: IMediaRecorder | undefined;
  private channelCount: number = 0;
  private sampleRate: number = 16000;

  // Méthode pour démarrer l'enregistrement audio
  async startRecording(
    onDataAvailable: (payload: any) => void,
    recordFromNavigator: boolean = false
  ) {
    try {
      this.audioContext = new AudioContext(
        isFirefox || isSafari ? undefined : { sampleRate: 16000 }
      );
      this.sampleRate = this.audioContext.sampleRate;

      this.micStream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
        },
      });

      const micSource = this.audioContext.createMediaStreamSource(
        this.micStream
      );

      const mediaStreamAudioDestinationNode =
        new MediaStreamAudioDestinationNode(
          this.audioContext,
          isFirefox || isSafari
            ? undefined
            : {
                channelCount: 1,
                channelCountMode: "explicit",
                channelInterpretation: "speakers",
              }
        );

      this.channelCount = mediaStreamAudioDestinationNode.channelCount;
      micSource.connect(mediaStreamAudioDestinationNode);

      let speakerSource: MediaStreamAudioSourceNode;

      if (recordFromNavigator) {
        const speakerStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: {
            echoCancellation: true,
          },
        });

        speakerStream.getVideoTracks().forEach((track) => track.stop());

        speakerSource =
          this.audioContext.createMediaStreamSource(speakerStream);

        speakerSource.connect(mediaStreamAudioDestinationNode);
      }

      this.mediaRecorder = new MediaRecorder(
        mediaStreamAudioDestinationNode.stream,
        {
          mimeType: "audio/wav",
          audioBitsPerSecond: isFirefox || isSafari ? 768000 : 256000,
        }
      );

      this.mediaRecorder.addEventListener("onStop", () => {
        micSource.disconnect();
      });

      this.mediaRecorder?.addEventListener("dataavailable", (event) => {
        console.log("dataavailable");
        const reader = new FileReader();
        const blob = event.data;
        reader.readAsDataURL(blob);

        reader.onloadend = () => {
          const payload = {
            state: "streaming",
            audio: reader.result,
            metadata: {
              mode: "dictation",
              report_id: null,
              navigator: getNavigator(),
              channel_count: isFirefox || isSafari ? 2 : this.channelCount,
              sample_rate: this.sampleRate,
            },
          };

          // Appel de la callback pour chaque dataavailable
          onDataAvailable(payload);
        };
      });

      this.mediaRecorder.start(500); // Commence à enregistrer par tranches de 500 ms
    } catch (error) {
      console.error("Erreur lors de l'enregistrement audio:", error);
    }
  }

  // Méthode pour arrêter l'enregistrement audio
  stopRecording() {
    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
    }

    if (this.micStream) {
      this.micStream.getTracks().forEach((track) => track.stop());
    }
  }
}
