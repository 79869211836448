import { useState, useReducer, useRef, useEffect } from "react";
import { ConfigProvider } from "antd";
import Context, { SaveReport } from "./context/Context";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Error from "./components/public/error/Error";
import Logout from "./components/public/logout/Logout";
import AuthController from "./components/public/authentication/AuthController";
import FlowReducer, { initialState } from "./reducer/flowReducer";
import Protected from "./components/Protected";
import Document from "./components/protected/document/Document";
import Templates from "./components/protected/templates/Templates";
import Layout from "./components/protected/layout/Layout";
import { ThianaAssistantMode, emptyReport } from "./types/Reports";
import { emptyTemplate, Template } from "./types/Templates";
import {
  Report as ThianaReport,
  Subscription,
  Professional,
  User,
  SubscriptionItem,
  Sit,
} from "@thiana/api-thiana-client";
import useTour from "./hooks/useTour";
import locale from "antd/es/locale/fr_FR";
import useDarkTheme from "./theme/useDarkTheme";
import useLightTheme from "./theme/useLightTheme";
import { ThianaAssistantTheme } from "./types/Theme";
import PaymentRedirect from "./components/protected/payments/PaymentRedirect";
import VoiceRecognitionAPI from "./components/protected/common/voice-recognition/VoiceRecognitionAPI";
import { TranscriptionObject } from "./types/AI/VoiceRecognition";
import { darkTheme, lightTheme } from "./theme/themes";
let tmpTheme = localStorage.getItem("theme");

function App() {
  const [user, setUser] = useState<User>();
  const [professional, setProfessional] = useState<Professional>();
  const [subscription, setSubscription] = useState<SubscriptionItem>();
  const [currentConversation, setCurrentConversation] = useState<string>("");
  const [fileAvatar, setFileAvatar] = useState<string>("");
  const [currentContent, setCurrentGeneration] = useState<string>("");
  const [currentCommand, setCurrentCommand] = useState<string>("");
  const [isASRProcessing, setIsASRProcessing] = useState<boolean>(false);
  const [isLLMProcessing, setIsLLMProcessing] = useState<boolean>(false);
  const [isMenuHidden, setIsMenuHidden] = useState<boolean>();
  const [currentTab, setCurrentTab] = useState<string>("transcription");
  const [state, dispatch] = useReducer(FlowReducer, initialState);
  const [isSaved, setIsSaved] = useState<SaveReport>({ isSaved: false });
  const [extractionStep, setExtractionStep] = useState<string>(
    "Extraction de l'anamnèse... (1/8)"
  );
  const [toBeCleaned, setToBeCleaned] = useState<boolean>(true);
  const [mode, setMode] = useState<ThianaAssistantMode>("conversation");
  const [teleconsultation, setTeleconsultation] = useState<boolean>(false);
  const [reports, setReports] = useState<ThianaReport[]>([]);
  const [currentReport, setCurrentReport] = useState<ThianaReport | undefined>(
    undefined
  );
  const [currentTemplate, setCurrentTemplate] =
    useState<Template>(emptyTemplate);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [isTemplateSaved, setIsTemplateSaved] = useState(true);
  const [isMicrophoneAvailable, setIsMicrophoneAvailable] =
    useState<boolean>(false);
  const [openTour, setOpenTour] = useState<boolean>(false);
  const { steps, stepsRef, setStepsRef } = useTour({
    updateOpenTour: setOpenTour,
  });

  // Gestion du theme
  const [theme, setTheme] = useState<"dark" | "light">("light");

  const SOCKET_ASR = useRef<WebSocket>();

  const voiceRecognitionAPI = useRef(new VoiceRecognitionAPI()).current;
  const [currentTranscriptionObject, setCurrentTranscriptionObject] = useState<
    TranscriptionObject | undefined
  >(undefined);

  const value = {
    user,
    updateUser: setUser,
    professional,
    updateProfessional: setProfessional,
    subscription,
    updateSubscription: setSubscription,
    fileAvatar,
    updateFileAvatar: setFileAvatar,
    currentConversation: currentConversation,
    updateCurrentTranscription: setCurrentConversation,
    currentCommand: currentCommand,
    updateCurrentCommand: setCurrentCommand,
    currentContent: currentContent,
    updateCurrentContent: setCurrentGeneration,
    isASRProcessing: isASRProcessing,
    updateIsASRProcessing: setIsASRProcessing,
    isLLMProcessing: isLLMProcessing,
    updateIsLLMProcessing: setIsLLMProcessing,
    extractionStep,
    updateExtractionStep: setExtractionStep,
    isMenuHidden: isMenuHidden,
    updateIsMenuHidden: setIsMenuHidden,
    currentTab,
    updateCurrentTab: setCurrentTab,
    flowState: state,
    dispatchFlow: dispatch,
    isSaved,
    updateIsSaved: setIsSaved,
    isTemplateSaved,
    updateIsTemplateSaved: setIsTemplateSaved,
    mode,
    updateMode: setMode,
    teleconsultation,
    updateTeleconsultation: setTeleconsultation,
    isMicrophoneAvailable,
    updateIsMicrophoneAvailable: setIsMicrophoneAvailable,
    stepsRef,
    updateStepsRef: setStepsRef,
    openTour,
    updateOpenTour: setOpenTour,
    currentReport,
    updateCurrentReport: setCurrentReport,
    currentTemplate,
    updateCurrentTemplate: setCurrentTemplate,
    reports,
    updateReports: setReports,
    theme,
    updateTheme: setTheme,
    toBeCleaned,
    updateToBeCleaned: setToBeCleaned,
    voiceRecognitionAPI,
    currentTranscriptionObject,
    updateCurrentTranscriptionObject: setCurrentTranscriptionObject,
  };

  const renderProtectedAndLayout = (component: JSX.Element) => (
    <Protected>
      <Layout
        SOCKET_ASR={SOCKET_ASR}
        reports={reports}
        setReports={setReports}
        templates={templates}
        setTemplates={setTemplates}
      >
        {component}
      </Layout>
    </Protected>
  );

  return (
    <Context.Provider value={value}>
      <ConfigProvider
        locale={locale}
        theme={theme === "light" ? lightTheme : darkTheme}
      >
        <BrowserRouter>
          <Routes>
            <Route path="" element={<AuthController />} />
            <Route
              path="/atk/:accessTokenFromURL/pwid/:personnalWorkspaceID"
              element={<AuthController />}
            />
            <Route
              path="/documents/:reportID?"
              element={renderProtectedAndLayout(
                <Document
                  templates={templates}
                  setTemplates={setTemplates}
                  SOCKET_ASR={SOCKET_ASR}
                  mode={mode}
                  steps={steps}
                />
              )}
            ></Route>

            <Route
              path="/templates/:templateID?"
              element={renderProtectedAndLayout(
                <Templates templates={templates} setTemplates={setTemplates} />
              )}
            />
            <Route path="/payments" element={<PaymentRedirect />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
    </Context.Provider>
  );
}

export default App;
