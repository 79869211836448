import { TranscriptionObject } from "../../../../../types/AI/VoiceRecognition";

export default function connectTranscriptionWebsocket(
  onTextAvailable: (payload: TranscriptionObject) => void
) {
  const accessToken = localStorage.getItem("accessJWT");
  const refreshToken = localStorage.getItem("refreshJWT");
  const websocket = new WebSocket(
    "wss://" + process.env.REACT_APP_URL_BACKAPP_WS + "/ws/v1/transcriptions",
    ["json", accessToken as string, refreshToken as string]
  );

  websocket.addEventListener("error", (event) => {
    console.error("Une erreur est survenue lors de la transcription.");
  });

  websocket.addEventListener("open", (event) => {
    console.log("WebSocket ouvert");
  });

  websocket.addEventListener("message", (event) => {
    onTextAvailable(JSON.parse(event.data) as TranscriptionObject);
  });

  websocket.addEventListener("close", () => {
    console.log("WebSocket closing...");
    setTimeout(() => {
      onTextAvailable({
        state: "closing",
        transcription: "",
        fully_validated: 0,
        in_validation: 0,
        validated: 0,
        transcription_id: 0,
      } as TranscriptionObject);
    }, 500);
    websocket.close();
  });

  return websocket;
}
