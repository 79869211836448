"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionItemToJSON = exports.SubscriptionItemFromJSONTyped = exports.SubscriptionItemFromJSON = exports.instanceOfSubscriptionItem = void 0;
/**
 * Check if a given object implements the SubscriptionItem interface.
 */
function instanceOfSubscriptionItem(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('payment_level' in value) || value['payment_level'] === undefined)
        return false;
    if (!('quantity' in value) || value['quantity'] === undefined)
        return false;
    if (!('status' in value) || value['status'] === undefined)
        return false;
    if (!('trial_until' in value) || value['trial_until'] === undefined)
        return false;
    if (!('valid_until' in value) || value['valid_until'] === undefined)
        return false;
    return true;
}
exports.instanceOfSubscriptionItem = instanceOfSubscriptionItem;
function SubscriptionItemFromJSON(json) {
    return SubscriptionItemFromJSONTyped(json, false);
}
exports.SubscriptionItemFromJSON = SubscriptionItemFromJSON;
function SubscriptionItemFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'payment_level': json['payment_level'],
        'quantity': json['quantity'],
        'status': json['status'],
        'trial_until': json['trial_until'],
        'valid_until': json['valid_until'],
    };
}
exports.SubscriptionItemFromJSONTyped = SubscriptionItemFromJSONTyped;
function SubscriptionItemToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'payment_level': value['payment_level'],
        'quantity': value['quantity'],
        'status': value['status'],
        'trial_until': value['trial_until'],
        'valid_until': value['valid_until'],
    };
}
exports.SubscriptionItemToJSON = SubscriptionItemToJSON;
