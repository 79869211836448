"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TranscriptionRecoveryOutputToJSON = exports.TranscriptionRecoveryOutputFromJSONTyped = exports.TranscriptionRecoveryOutputFromJSON = exports.instanceOfTranscriptionRecoveryOutput = void 0;
var Metadata_1 = require("./Metadata");
var TranscriptionRecovery_1 = require("./TranscriptionRecovery");
/**
 * Check if a given object implements the TranscriptionRecoveryOutput interface.
 */
function instanceOfTranscriptionRecoveryOutput(value) {
    if (!('data' in value) || value['data'] === undefined)
        return false;
    return true;
}
exports.instanceOfTranscriptionRecoveryOutput = instanceOfTranscriptionRecoveryOutput;
function TranscriptionRecoveryOutputFromJSON(json) {
    return TranscriptionRecoveryOutputFromJSONTyped(json, false);
}
exports.TranscriptionRecoveryOutputFromJSON = TranscriptionRecoveryOutputFromJSON;
function TranscriptionRecoveryOutputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': (json['data'].map(TranscriptionRecovery_1.TranscriptionRecoveryFromJSON)),
        'metadata': json['metadata'] == null ? undefined : (0, Metadata_1.MetadataFromJSON)(json['metadata']),
    };
}
exports.TranscriptionRecoveryOutputFromJSONTyped = TranscriptionRecoveryOutputFromJSONTyped;
function TranscriptionRecoveryOutputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'data': (value['data'].map(TranscriptionRecovery_1.TranscriptionRecoveryToJSON)),
        'metadata': (0, Metadata_1.MetadataToJSON)(value['metadata']),
    };
}
exports.TranscriptionRecoveryOutputToJSON = TranscriptionRecoveryOutputToJSON;
