"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OtherOutputToJSON = exports.OtherOutputFromJSONTyped = exports.OtherOutputFromJSON = exports.instanceOfOtherOutput = void 0;
var Metadata_1 = require("./Metadata");
var Other_1 = require("./Other");
/**
 * Check if a given object implements the OtherOutput interface.
 */
function instanceOfOtherOutput(value) {
    if (!('data' in value) || value['data'] === undefined)
        return false;
    return true;
}
exports.instanceOfOtherOutput = instanceOfOtherOutput;
function OtherOutputFromJSON(json) {
    return OtherOutputFromJSONTyped(json, false);
}
exports.OtherOutputFromJSON = OtherOutputFromJSON;
function OtherOutputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': (json['data'].map(Other_1.OtherFromJSON)),
        'metadata': json['metadata'] == null ? undefined : (0, Metadata_1.MetadataFromJSON)(json['metadata']),
    };
}
exports.OtherOutputFromJSONTyped = OtherOutputFromJSONTyped;
function OtherOutputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'data': (value['data'].map(Other_1.OtherToJSON)),
        'metadata': (0, Metadata_1.MetadataToJSON)(value['metadata']),
    };
}
exports.OtherOutputToJSON = OtherOutputToJSON;
