"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportTemplateToJSON = exports.ReportTemplateFromJSONTyped = exports.ReportTemplateFromJSON = exports.instanceOfReportTemplate = void 0;
/**
 * Check if a given object implements the ReportTemplate interface.
 */
function instanceOfReportTemplate(value) {
    if (!('content' in value) || value['content'] === undefined)
        return false;
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('deleted_at' in value) || value['deleted_at'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('parent_id' in value) || value['parent_id'] === undefined)
        return false;
    if (!('professional_id' in value) || value['professional_id'] === undefined)
        return false;
    if (!('title' in value) || value['title'] === undefined)
        return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined)
        return false;
    if (!('version' in value) || value['version'] === undefined)
        return false;
    return true;
}
exports.instanceOfReportTemplate = instanceOfReportTemplate;
function ReportTemplateFromJSON(json) {
    return ReportTemplateFromJSONTyped(json, false);
}
exports.ReportTemplateFromJSON = ReportTemplateFromJSON;
function ReportTemplateFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'content': json['content'],
        'created_at': json['created_at'],
        'deleted_at': json['deleted_at'],
        'id': json['id'],
        'parent_id': json['parent_id'],
        'professional_id': json['professional_id'],
        'title': json['title'],
        'updated_at': json['updated_at'],
        'version': json['version'],
    };
}
exports.ReportTemplateFromJSONTyped = ReportTemplateFromJSONTyped;
function ReportTemplateToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'content': value['content'],
        'created_at': value['created_at'],
        'deleted_at': value['deleted_at'],
        'id': value['id'],
        'parent_id': value['parent_id'],
        'professional_id': value['professional_id'],
        'title': value['title'],
        'updated_at': value['updated_at'],
        'version': value['version'],
    };
}
exports.ReportTemplateToJSON = ReportTemplateToJSON;
