"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DoctorInputToJSON = exports.DoctorInputFromJSONTyped = exports.DoctorInputFromJSON = exports.instanceOfDoctorInput = void 0;
/**
 * Check if a given object implements the DoctorInput interface.
 */
function instanceOfDoctorInput(value) {
    if (!('professional_id' in value) || value['professional_id'] === undefined)
        return false;
    return true;
}
exports.instanceOfDoctorInput = instanceOfDoctorInput;
function DoctorInputFromJSON(json) {
    return DoctorInputFromJSONTyped(json, false);
}
exports.DoctorInputFromJSON = DoctorInputFromJSON;
function DoctorInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'professional_id': json['professional_id'],
        'rpps': json['rpps'] == null ? undefined : json['rpps'],
        'speciality': json['speciality'] == null ? undefined : json['speciality'],
    };
}
exports.DoctorInputFromJSONTyped = DoctorInputFromJSONTyped;
function DoctorInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'professional_id': value['professional_id'],
        'rpps': value['rpps'],
        'speciality': value['speciality'],
    };
}
exports.DoctorInputToJSON = DoctorInputToJSON;
