import { Navigate } from "react-router-dom";
import { hasValidToken } from "../utils/security";
import { useContext, useEffect } from "react";
import Context from "../context/Context";
import { getFileFromS3 } from "../api/api";
import { EMPTY_UUID } from "../utils/constants";
import { configuration } from "../api-configuration/Configuration";
import {
  AuthApi,
  Me,
  MeOutput,
  PaymentsApi,
  Subscription,
  SubscriptionItem,
  SubscriptionOutput,
} from "@thiana/api-thiana-client";
import { AccessLevelOptions } from "../types/CustomSubscription";

const Protected = (props: any) => {
  const {
    updateUser,
    updateProfessional,
    updateSubscription,
    updateFileAvatar,
  } = useContext(Context);

  const authApi = new AuthApi(configuration);
  const paymentsApi = new PaymentsApi(configuration);

  // useEffect to get user informations
  useEffect(() => {
    (async () => {
      let response = await authApi.authUserMeRaw();
      if (response.raw.status === 200) {
        let meOutput: MeOutput = await response.raw.json();
        let meData: Me = meOutput.data[0];
        let getFileResponse: any;
        console.log("meData", meData);
        updateUser(meData.user);
        updateProfessional(meData.professional);
        updateSubscription(
          meData.workspaces[0].subscription.items.find(
            (subscriptionItem: SubscriptionItem) =>
              subscriptionItem.status === "active" ||
              subscriptionItem.status === "trialing"
          )
        );
        // fetch 2 : Si il y a un avatar, on le récupère
        if (
          meData.professional?.avatar &&
          meData.professional?.avatar !== EMPTY_UUID
        ) {
          let response2 = await getFileFromS3(meData.professional.avatar);
          if (response2.status === 200) {
            getFileResponse = await response2.blob();
            updateFileAvatar(URL.createObjectURL(getFileResponse));
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!hasValidToken()) {
    return <Navigate to="/" replace />;
  }
  return props.children;
};
export default Protected;
