import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  Input,
  Modal,
  Tag,
  Tooltip,
  Typography,
  theme as antdTheme,
} from "antd";
import {
  CheckOutlined,
  CopyOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import Context from "../../../../../../context/Context";
import { copyToClipboard } from "../../../../../../functions/copyToClipboard";
import useReports from "../../../../../../hooks/useReports";
import { useNavigate, useParams } from "react-router-dom";
import ControlCommands from "../../../../commands/ControlCommands";
import { ThianaAssistantMode } from "../../../../../../types/Reports";
import { Template } from "../../../../../../types/Templates";
import backgroundConversationImage from "../../../../../../assets/tour/doctor-patient-conversation.svg";
import { DEFAULT_TEMPLATE_UUID } from "../../../../../../utils/constants";
import {
  ApiResponse,
  ReportOutput,
  ReportsApi,
  Report,
} from "@thiana/api-thiana-client";
import { configuration } from "../../../../../../api-configuration/Configuration";
import useTypeWriter from "../../../../../../hooks/useTypeWriter";
import {
  emptyTranscriptionObject,
  TranscriptionObject,
} from "../../../../../../types/AI/VoiceRecognition";

interface Props {
  SOCKET_ASR: React.MutableRefObject<WebSocket | undefined>;
  templates: Template[];
  mode: ThianaAssistantMode;
}

export default function Conversation(props: Props) {
  let { reportID } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [textToDisplay, setTextToDisplay] = useState<string>("");
  const { token } = antdTheme.useToken();

  const {
    mode,
    isASRProcessing,
    isLLMProcessing,
    isSaved,
    stepsRef,
    currentReport,
    updateCurrentReport,
    updateIsSaved,
    currentTranscriptionObject,
    updateCurrentTranscriptionObject,
    dispatchFlow,
    theme,
  } = useContext(Context);

  const { autoSaveReport, autoCreateReport, updateReport } = useReports({});

  const reportsAPI = new ReportsApi(configuration);

  const navigate = useNavigate();

  const { typeWriter, displayText, resetTypeWriter } = useTypeWriter();

  useEffect(() => {
    (async () => {
      console.log("currentTranscriptionObject", currentTranscriptionObject);
      if (currentTranscriptionObject?.state === "closing") {
        if (reportID === undefined) {
          const reportResponse: ApiResponse<ReportOutput> =
            await reportsAPI.reportCreateRaw({
              reportInput: {
                ...currentReport,
                transcription: currentTranscriptionObject?.transcription,
              },
            });
          if (!reportResponse.raw.ok) {
            console.error("Report creation error");
          } else {
            const reportOutput: ReportOutput = await reportResponse.raw.json();
            const report: Report = reportOutput.data[0];
            updateCurrentReport(report);
            navigate("/documents/" + report.id);
          }
        } else {
          const reportUpdateResponse = await reportsAPI.reportUpdateRaw({
            id: reportID,
            reportInput: {
              ...currentReport,
              transcription:
                currentReport?.transcription +
                " " +
                currentTranscriptionObject.transcription,
            },
          });
          if (!reportUpdateResponse.raw.ok) {
            console.error("Report update error");
          } else {
            updateCurrentReport({
              ...currentReport,
              transcription:
                currentReport?.transcription +
                " " +
                currentTranscriptionObject.transcription,
            } as Report);
          }
        }
        dispatchFlow({ type: "SOCKET_ASR_STOP" });
        resetTypeWriter();
      } else {
        if (currentTranscriptionObject?.transcription)
          typeWriter(
            currentTranscriptionObject.transcription as string,
            setTextToDisplay
          );
      }
    })();
  }, [currentTranscriptionObject]);

  const onChangeTranscription = (newValue: string) => {
    console.log(currentReport);
    if (currentReport && mode) {
      updateIsSaved({ isSaved: false, updatedField: "transcription" });
      updateCurrentReport({
        ...currentReport,
        transcription: newValue,
      });
      if (reportID)
        autoSaveReport({
          ...currentReport,
          transcription: newValue,
        });
      else
        autoCreateReport({
          generation: currentReport.generation,
          title: "",
          transcription: newValue,
          report_template_id: DEFAULT_TEMPLATE_UUID,
        });
    }
  };

  const HeaderConversation = () => (
    <Flex
      align="center"
      onMouseEnter={() => setIsFocus(true)}
      onMouseLeave={() => setIsFocus(false)}
      // style={{
      //   color: isFocus || isASRProcessing ? "#6240dc" : "#bbbbbb",
      //   marginBottom: 4,
      // }}
    >
      <div style={{ flex: 1 }}>
        {currentReport?.transcription === "" ? (
          <Tag
            bordered={false}
            style={{
              fontSize: 10,
            }}
          >
            CONVERSATION
          </Tag>
        ) : !isSaved.isSaved && isSaved.updatedField === "transcription" ? (
          <Tag bordered={false} color="orange" style={{ fontSize: 10 }}>
            MODIFICATION EN COURS <LoadingOutlined />
          </Tag>
        ) : (
          <Tag bordered={false} color="green" style={{ fontSize: 10 }}>
            CONVERSATION <CheckOutlined style={{ fontSize: 8 }} />
          </Tag>
        )}
      </div>
      <div>
        <Tooltip title="Effacer la transcription" mouseEnterDelay={0.8}>
          <Button
            type={"text"}
            icon={<DeleteOutlined />}
            onClick={() => setIsModalOpen(true)}
          />
        </Tooltip>

        <Tooltip title="Copier la transcription" mouseEnterDelay={0.8}>
          <Button
            type={"text"}
            icon={<CopyOutlined />}
            onClick={() => copyToClipboard(currentReport?.transcription)}
          />
        </Tooltip>
      </div>
    </Flex>
  );

  return (
    <Flex
      vertical
      gap={8}
      style={{
        height: "100%",
      }}
      ref={stepsRef[5]}
    >
      <Flex
        vertical
        style={{
          flex: 1,
        }}
      >
        <HeaderConversation />
        {isASRProcessing ? (
          <Flex
            vertical
            style={{
              border: "1px solid " + token.colorPrimary,
              borderRadius: 8,
              backgroundImage: `url(${backgroundConversationImage})`,
              backgroundSize: "80%",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              height: "calc(100vh - 218px)",
              width: "100%",
              overflowY: "scroll",
              boxSizing: "border-box",
            }}
          >
            <Flex
              flex={1}
              style={{
                boxSizing: "border-box",
                borderRadius: 8,
                paddingTop: 4,
                paddingBottom: 4,
                paddingLeft: 10,
                paddingRight: 10,
                letterSpacing: 0.25,
                wordSpacing: 1.1,
                width: "100%",
                backgroundColor:
                  theme === "light"
                    ? "rgba(255,255,255,0.9)"
                    : "rgba(255,255,255,0.1)",
              }}
            >
              {displayText(
                currentReport?.transcription as string,
                textToDisplay,
                currentTranscriptionObject as TranscriptionObject
              )}
            </Flex>
          </Flex>
        ) : (
          <Flex
            vertical
            style={{
              flex: 1,
              backgroundImage: `url(${backgroundConversationImage})`,
              backgroundSize: "80%",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Input.TextArea
              onFocus={() => (isFocus ? setIsFocus(true) : null)}
              onBlur={() => setIsFocus(false)}
              style={{
                flex: 1,
                textAlign: "justify",
                borderRadius: 8,
                backgroundColor:
                  theme === "light"
                    ? "rgba(255,255,255,0.9)"
                    : "rgba(26,37,57,0.9)",
              }}
              value={currentReport?.transcription}
              onChange={(e) => onChangeTranscription(e.target.value)}
              disabled={isLLMProcessing || isASRProcessing}
              onMouseEnter={() => setIsFocus(true)}
              onMouseLeave={() => setIsFocus(false)}
              data-cy="action-input-transcription"
            ></Input.TextArea>
          </Flex>
        )}
      </Flex>

      <Modal
        title={
          <>
            <ExclamationCircleFilled
              style={{
                height: 32,
                width: 32,
                fontSize: 22,
                color: "#ff4d4f",
              }}
            />
            Etes-vous sûr de vouloir effacer la transcription ?
          </>
        }
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button
            key="back"
            type="default"
            onClick={() => setIsModalOpen(false)}
          >
            Annuler
          </Button>,
          <Button
            onClick={() => {
              if (currentReport) {
                updateCurrentReport({
                  ...currentReport,
                  transcription: "",
                });
                updateReport({
                  ...currentReport,
                  transcription: "",
                });
                setIsModalOpen(false);
              }
            }}
            type="primary"
            danger
            key="submit"
          >
            Supprimer
          </Button>,
        ]}
      >
        <p>
          Une fois supprimées, les données seront définitivement perdues.
          Etes-vous sur de vouloir continuer ?
        </p>
        <p>Si non, cliquez sur le bouton annuler</p>
      </Modal>

      <Flex gap={4}>
        <ControlCommands
          SOCKET_ASR={props.SOCKET_ASR}
          mode={props.mode}
          templates={props.templates}
        />
      </Flex>
    </Flex>
  );
}
