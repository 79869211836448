"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressInputToJSON = exports.AddressInputFromJSONTyped = exports.AddressInputFromJSON = exports.instanceOfAddressInput = void 0;
/**
 * Check if a given object implements the AddressInput interface.
 */
function instanceOfAddressInput(value) {
    if (!('city' in value) || value['city'] === undefined)
        return false;
    if (!('street' in value) || value['street'] === undefined)
        return false;
    if (!('street2' in value) || value['street2'] === undefined)
        return false;
    if (!('zip_code' in value) || value['zip_code'] === undefined)
        return false;
    return true;
}
exports.instanceOfAddressInput = instanceOfAddressInput;
function AddressInputFromJSON(json) {
    return AddressInputFromJSONTyped(json, false);
}
exports.AddressInputFromJSON = AddressInputFromJSON;
function AddressInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'accessCode': json['accessCode'] == null ? undefined : json['accessCode'],
        'appartementNumber': json['appartementNumber'] == null ? undefined : json['appartementNumber'],
        'building': json['building'] == null ? undefined : json['building'],
        'city': json['city'],
        'floor': json['floor'] == null ? undefined : json['floor'],
        'staircase': json['staircase'] == null ? undefined : json['staircase'],
        'street': json['street'],
        'street2': json['street2'],
        'zip_code': json['zip_code'],
    };
}
exports.AddressInputFromJSONTyped = AddressInputFromJSONTyped;
function AddressInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'accessCode': value['accessCode'],
        'appartementNumber': value['appartementNumber'],
        'building': value['building'],
        'city': value['city'],
        'floor': value['floor'],
        'staircase': value['staircase'],
        'street': value['street'],
        'street2': value['street2'],
        'zip_code': value['zip_code'],
    };
}
exports.AddressInputToJSON = AddressInputToJSON;
