"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportFeedbackToJSON = exports.ReportFeedbackFromJSONTyped = exports.ReportFeedbackFromJSON = exports.instanceOfReportFeedback = void 0;
/**
 * Check if a given object implements the ReportFeedback interface.
 */
function instanceOfReportFeedback(value) {
    if (!('comment' in value) || value['comment'] === undefined)
        return false;
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('professional_id' in value) || value['professional_id'] === undefined)
        return false;
    if (!('rate' in value) || value['rate'] === undefined)
        return false;
    if (!('report_id' in value) || value['report_id'] === undefined)
        return false;
    return true;
}
exports.instanceOfReportFeedback = instanceOfReportFeedback;
function ReportFeedbackFromJSON(json) {
    return ReportFeedbackFromJSONTyped(json, false);
}
exports.ReportFeedbackFromJSON = ReportFeedbackFromJSON;
function ReportFeedbackFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'comment': json['comment'],
        'created_at': json['created_at'],
        'id': json['id'],
        'professional_id': json['professional_id'],
        'rate': json['rate'],
        'report_id': json['report_id'],
    };
}
exports.ReportFeedbackFromJSONTyped = ReportFeedbackFromJSONTyped;
function ReportFeedbackToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'comment': value['comment'],
        'created_at': value['created_at'],
        'id': value['id'],
        'professional_id': value['professional_id'],
        'rate': value['rate'],
        'report_id': value['report_id'],
    };
}
exports.ReportFeedbackToJSON = ReportFeedbackToJSON;
