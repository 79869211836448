import { TranscriptionObject } from "../../../../types/AI/VoiceRecognition";
import AudioRecorder from "./functions/AudioRecorder";
import connectTranscriptionWebsocket from "./functions/connectTranscriptionWebsocket";

export default class VoiceRecognitionAPI {
  private websocket: WebSocket | undefined = undefined;
  private audioRecorder: AudioRecorder | undefined = undefined;
  private transcription: string = "";

  async startTranscription(
    onChangeTranscription: (data: TranscriptionObject) => void,
    recordFromNavigator: boolean = false
  ) {
    this.websocket = connectTranscriptionWebsocket(
      (data: TranscriptionObject) => {
        console.log("data", data);
        if (data.state !== "closing") {
          this.transcription = data.transcription;
          onChangeTranscription(data);
        } else {
          console.log("this.transcription", this.transcription);
          onChangeTranscription({ ...data, transcription: this.transcription });
          this.transcription = "";
        }
      }
    );

    try {
      this.audioRecorder = new AudioRecorder();

      await this.audioRecorder.startRecording((payload: any) => {
        this.websocket?.send(JSON.stringify(payload));
      }, recordFromNavigator);
    } catch (error) {
      console.error("Erreur lors du démarrage de la transcription :", error);
    }
  }

  async stopTranscription(callback: () => void) {
    console.log("stopTranscription");
    this.audioRecorder?.stopRecording();
    setTimeout(() => {
      this.websocket?.send(JSON.stringify({ state: "ending" }));
      callback();
    }, 500);
  }
}
