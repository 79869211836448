"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuggestionInputToJSON = exports.SuggestionInputFromJSONTyped = exports.SuggestionInputFromJSON = exports.instanceOfSuggestionInput = void 0;
/**
 * Check if a given object implements the SuggestionInput interface.
 */
function instanceOfSuggestionInput(value) {
    if (!('content' in value) || value['content'] === undefined)
        return false;
    if (!('conversation_id' in value) || value['conversation_id'] === undefined)
        return false;
    if (!('status' in value) || value['status'] === undefined)
        return false;
    if (!('title' in value) || value['title'] === undefined)
        return false;
    return true;
}
exports.instanceOfSuggestionInput = instanceOfSuggestionInput;
function SuggestionInputFromJSON(json) {
    return SuggestionInputFromJSONTyped(json, false);
}
exports.SuggestionInputFromJSON = SuggestionInputFromJSON;
function SuggestionInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'content': json['content'],
        'conversation_id': json['conversation_id'],
        'status': json['status'],
        'title': json['title'],
    };
}
exports.SuggestionInputFromJSONTyped = SuggestionInputFromJSONTyped;
function SuggestionInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'content': value['content'],
        'conversation_id': value['conversation_id'],
        'status': value['status'],
        'title': value['title'],
    };
}
exports.SuggestionInputToJSON = SuggestionInputToJSON;
