import React, { useCallback, useEffect, useRef } from "react";
import { TranscriptionObject } from "../types/AI/VoiceRecognition";
import { Typography, theme } from "antd";

export default function useTypeWriter() {
  const timeoutRef = useRef<number | undefined>(); // Garde la trace de l'ID du setTimeout
  let wordsCount = useRef(0);
  const { token } = theme.useToken();

  const typeWriter = useCallback(
    (text: string, callback: (text: string) => void) => {
      let splitedText = text.split(" ");
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = window.setTimeout(() => {
        if (wordsCount.current < splitedText.length) {
          callback(splitedText.slice(0, wordsCount.current).join(" "));
          wordsCount.current += 1;
          typeWriter(text, callback);
        }
      }, 50);
    },
    []
  );

  const resetTypeWriter = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    wordsCount.current = 0;
  }, []);

  const displayText = (
    cachedText: string,
    textToDisplay: string,
    transcriptionObject: TranscriptionObject
  ) => {
    return (
      <p
        style={{
          margin: 0,
          lineHeight: 1.5714,
          fontSize: 14,
          padding: 0,
        }} //line-height similaire au Input.TextArea de ant design
      >
        <Typography>{cachedText}</Typography>
        {/* <Typography>{transcriptionObject?.transcription}</Typography> */}
        <Typography.Text>
          {transcriptionObject
            ? textToDisplay.slice(0, transcriptionObject?.fully_validated)
            : null}
        </Typography.Text>
        <Typography.Text style={{ color: token["blue-8"] }}>
          {transcriptionObject
            ? textToDisplay.slice(
                transcriptionObject?.fully_validated,

                transcriptionObject?.fully_validated +
                  transcriptionObject?.validated
              )
            : null}
        </Typography.Text>
        <Typography.Text style={{ color: token.colorPrimaryText }}>
          {transcriptionObject
            ? textToDisplay.slice(
                transcriptionObject?.fully_validated +
                  transcriptionObject?.validated,

                transcriptionObject?.fully_validated +
                  transcriptionObject?.validated +
                  transcriptionObject?.in_validation
              )
            : null}
        </Typography.Text>
      </p>
    );
  };

  return { typeWriter, resetTypeWriter, displayText };
}
