import { useContext, useEffect } from "react";
import Context from "../../../context/Context";
import { Flex, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export default function Logout() {
  const { updateUser, updateProfessional, updateSubscription } =
    useContext(Context);
  useEffect(() => {
    setTimeout(() => {
      localStorage.removeItem("accessJWT");
      localStorage.removeItem("refreshJWT");
      updateUser(undefined);
      updateSubscription(undefined);
      updateProfessional(undefined);
      if (process.env.REACT_APP_URL_THIANA_AUTH_LOGOUT)
        window.location.href = process.env.REACT_APP_URL_THIANA_AUTH_LOGOUT;
    }, 2000);
  });

  const { theme } = useContext(Context);

  return (
    <Flex
      vertical
      gap={"middle"}
      align="center"
      justify="center"
      style={{
        height: "100vh",
      }}
    >
      <LoadingOutlined style={{ fontSize: 24 }} />
      <Typography>Déconnexion de Thiana Assistant</Typography>
    </Flex>
  );
}
