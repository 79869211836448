"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvitationOutputToJSON = exports.InvitationOutputFromJSONTyped = exports.InvitationOutputFromJSON = exports.instanceOfInvitationOutput = void 0;
var Invitation_1 = require("./Invitation");
var Metadata_1 = require("./Metadata");
/**
 * Check if a given object implements the InvitationOutput interface.
 */
function instanceOfInvitationOutput(value) {
    if (!('data' in value) || value['data'] === undefined)
        return false;
    return true;
}
exports.instanceOfInvitationOutput = instanceOfInvitationOutput;
function InvitationOutputFromJSON(json) {
    return InvitationOutputFromJSONTyped(json, false);
}
exports.InvitationOutputFromJSON = InvitationOutputFromJSON;
function InvitationOutputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': (json['data'].map(Invitation_1.InvitationFromJSON)),
        'metadata': json['metadata'] == null ? undefined : (0, Metadata_1.MetadataFromJSON)(json['metadata']),
    };
}
exports.InvitationOutputFromJSONTyped = InvitationOutputFromJSONTyped;
function InvitationOutputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'data': (value['data'].map(Invitation_1.InvitationToJSON)),
        'metadata': (0, Metadata_1.MetadataToJSON)(value['metadata']),
    };
}
exports.InvitationOutputToJSON = InvitationOutputToJSON;
