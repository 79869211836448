"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportTemplateShortcutInputToJSON = exports.ReportTemplateShortcutInputFromJSONTyped = exports.ReportTemplateShortcutInputFromJSON = exports.instanceOfReportTemplateShortcutInput = void 0;
/**
 * Check if a given object implements the ReportTemplateShortcutInput interface.
 */
function instanceOfReportTemplateShortcutInput(value) {
    if (!('content' in value) || value['content'] === undefined)
        return false;
    if (!('title' in value) || value['title'] === undefined)
        return false;
    return true;
}
exports.instanceOfReportTemplateShortcutInput = instanceOfReportTemplateShortcutInput;
function ReportTemplateShortcutInputFromJSON(json) {
    return ReportTemplateShortcutInputFromJSONTyped(json, false);
}
exports.ReportTemplateShortcutInputFromJSON = ReportTemplateShortcutInputFromJSON;
function ReportTemplateShortcutInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'content': json['content'],
        'title': json['title'],
    };
}
exports.ReportTemplateShortcutInputFromJSONTyped = ReportTemplateShortcutInputFromJSONTyped;
function ReportTemplateShortcutInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'content': value['content'],
        'title': value['title'],
    };
}
exports.ReportTemplateShortcutInputToJSON = ReportTemplateShortcutInputToJSON;
