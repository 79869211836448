"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuggestionOutputToJSON = exports.SuggestionOutputFromJSONTyped = exports.SuggestionOutputFromJSON = exports.instanceOfSuggestionOutput = void 0;
var Suggestion_1 = require("./Suggestion");
var Metadata_1 = require("./Metadata");
/**
 * Check if a given object implements the SuggestionOutput interface.
 */
function instanceOfSuggestionOutput(value) {
    if (!('data' in value) || value['data'] === undefined)
        return false;
    return true;
}
exports.instanceOfSuggestionOutput = instanceOfSuggestionOutput;
function SuggestionOutputFromJSON(json) {
    return SuggestionOutputFromJSONTyped(json, false);
}
exports.SuggestionOutputFromJSON = SuggestionOutputFromJSON;
function SuggestionOutputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': (json['data'].map(Suggestion_1.SuggestionFromJSON)),
        'metadata': json['metadata'] == null ? undefined : (0, Metadata_1.MetadataFromJSON)(json['metadata']),
    };
}
exports.SuggestionOutputFromJSONTyped = SuggestionOutputFromJSONTyped;
function SuggestionOutputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'data': (value['data'].map(Suggestion_1.SuggestionToJSON)),
        'metadata': (0, Metadata_1.MetadataToJSON)(value['metadata']),
    };
}
exports.SuggestionOutputToJSON = SuggestionOutputToJSON;
