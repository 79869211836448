import { RiseOutlined } from "@ant-design/icons";
import { useContext, useState, useEffect } from "react";
import Context from "../../../context/Context";
import { Button, Flex, Modal, Tag, Tooltip, Typography } from "antd";
import { getFormattedACL } from "../../../utils/security";
import { AuthApi, SubscriptionItem } from "@thiana/api-thiana-client";
import { configuration } from "../../../api-configuration/Configuration";
import PaymentModalContent from "./payment/PaymentModalContent";
import { CustomSubscription } from "../../../types/CustomSubscription";

const stripeBillingURL = process.env.REACT_APP_URL_STRIPE;

const stripeCheckoutURL = "";

export default function Subscribe() {
  const [hover, serHover] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const { subscription } = useContext(Context);

  const paymentAction = (subscription: SubscriptionItem) => {
    if (
      subscription.status === "trialing" ||
      subscription.payment_level === "assistant unpaid"
    ) {
      setIsPaymentModalOpen(true);
    } else {
      window.open(stripeBillingURL, "_blank");
    }
  };

  const computeTrialDaysNumber = (subscription: SubscriptionItem): any => {
    const { trial_until } = subscription;

    const [day, month, yearAndTime] = trial_until.split("/");
    const [year, time] = yearAndTime.split(" ");
    const [hours, minutes, seconds] = time.split(":").map(Number);

    const endDate = new Date(
      Number(year),
      Number(month) - 1, // Les mois en JavaScript commencent à 0
      Number(day),
      hours,
      minutes,
      seconds
    );

    const now = new Date();

    const diffInMs = endDate.getTime() - now.getTime();

    const daysRemaining = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

    if (daysRemaining > 15)
      return (
        <Typography.Text>
          Encore {daysRemaining} jours d'essai gratuit
        </Typography.Text>
      );
    else if (daysRemaining > 5)
      return (
        <Typography.Text style={{ color: "orange" }}>
          Plus que {daysRemaining} jours d'essai gratuit
        </Typography.Text>
      );
    else
      return (
        <Typography.Text style={{ color: "red" }}>
          Plus que {daysRemaining} jours d'essai gratuit
        </Typography.Text>
      );
  };

  const renderPaymentInfos = (subscription: SubscriptionItem) => {
    if (subscription.payment_level === "assistant unpaid") {
      return (
        <Typography.Text
          strong
          style={{ fontWeight: "bold", textAlign: "center" }}
        >
          Vous n'avez aucun abonnement. Cliquez ici pour vous abonner
        </Typography.Text>
      );
    } else
      switch (subscription.status) {
        case "active":
          return (
            <Typography>
              Abonnement{" "}
              {subscription.payment_level === "assistant essential"
                ? "offre essentielle"
                : "offre integrale"}{" "}
              actif.
            </Typography>
          );
        case "trialing":
          return computeTrialDaysNumber(subscription);
        default:
          return (
            <Typography>Erreur lors de l'analyse de la subscription</Typography>
          );
      }
  };

  return (
    <>
      <Tooltip
        title="Cliquez pour gérer votre abonnement."
        mouseEnterDelay={subscription?.status === "active" ? 0.3 : 9999}
      >
        <Flex
          onMouseEnter={() => serHover(true)}
          onMouseLeave={() => serHover(false)}
          style={{
            padding: 8,
            borderRadius: 8,
            backgroundColor: hover ? "rgba(0,0,0,0.1)" : "",
            color: hover ? "white" : "",
            cursor: "pointer",
          }}
          onClick={() => (subscription ? paymentAction(subscription) : null)}
          align="center"
          justify="center"
        >
          {subscription ? (
            renderPaymentInfos(subscription)
          ) : (
            <Typography.Text>Aucun abonnement détecté.</Typography.Text>
          )}
        </Flex>
      </Tooltip>
      <Modal
        title="Choisissez une offre"
        open={isPaymentModalOpen}
        onCancel={() => setIsPaymentModalOpen(false)}
        footer={[
          <Button onClick={() => setIsPaymentModalOpen(false)}>Annuler</Button>,
        ]}
        width={1200}
      >
        <PaymentModalContent />
      </Modal>
    </>
  );
}
