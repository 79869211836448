"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OtherToJSON = exports.OtherFromJSONTyped = exports.OtherFromJSON = exports.instanceOfOther = void 0;
/**
 * Check if a given object implements the Other interface.
 */
function instanceOfOther(value) {
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('deleted_at' in value) || value['deleted_at'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('profession' in value) || value['profession'] === undefined)
        return false;
    if (!('professional_id' in value) || value['professional_id'] === undefined)
        return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined)
        return false;
    return true;
}
exports.instanceOfOther = instanceOfOther;
function OtherFromJSON(json) {
    return OtherFromJSONTyped(json, false);
}
exports.OtherFromJSON = OtherFromJSON;
function OtherFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'created_at': json['created_at'],
        'deleted_at': json['deleted_at'],
        'id': json['id'],
        'profession': json['profession'],
        'professional_id': json['professional_id'],
        'updated_at': json['updated_at'],
    };
}
exports.OtherFromJSONTyped = OtherFromJSONTyped;
function OtherToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'created_at': value['created_at'],
        'deleted_at': value['deleted_at'],
        'id': value['id'],
        'profession': value['profession'],
        'professional_id': value['professional_id'],
        'updated_at': value['updated_at'],
    };
}
exports.OtherToJSON = OtherToJSON;
