"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetailedWorkspaceToJSON = exports.DetailedWorkspaceFromJSONTyped = exports.DetailedWorkspaceFromJSON = exports.instanceOfDetailedWorkspace = void 0;
var EmbeddedCustomer_1 = require("./EmbeddedCustomer");
var EmbeddedSubscription_1 = require("./EmbeddedSubscription");
var Sit_1 = require("./Sit");
/**
 * Check if a given object implements the DetailedWorkspace interface.
 */
function instanceOfDetailedWorkspace(value) {
    if (!('address_id' in value) || value['address_id'] === undefined)
        return false;
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('customer' in value) || value['customer'] === undefined)
        return false;
    if (!('deleted_at' in value) || value['deleted_at'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('logo_id' in value) || value['logo_id'] === undefined)
        return false;
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('professional_id' in value) || value['professional_id'] === undefined)
        return false;
    if (!('sits' in value) || value['sits'] === undefined)
        return false;
    if (!('subscription' in value) || value['subscription'] === undefined)
        return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined)
        return false;
    return true;
}
exports.instanceOfDetailedWorkspace = instanceOfDetailedWorkspace;
function DetailedWorkspaceFromJSON(json) {
    return DetailedWorkspaceFromJSONTyped(json, false);
}
exports.DetailedWorkspaceFromJSON = DetailedWorkspaceFromJSON;
function DetailedWorkspaceFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'address_id': json['address_id'],
        'created_at': json['created_at'],
        'customer': (0, EmbeddedCustomer_1.EmbeddedCustomerFromJSON)(json['customer']),
        'deleted_at': json['deleted_at'],
        'id': json['id'],
        'logo_id': json['logo_id'],
        'name': json['name'],
        'professional_id': json['professional_id'],
        'sits': (json['sits'].map(Sit_1.SitFromJSON)),
        'subscription': (0, EmbeddedSubscription_1.EmbeddedSubscriptionFromJSON)(json['subscription']),
        'updated_at': json['updated_at'],
    };
}
exports.DetailedWorkspaceFromJSONTyped = DetailedWorkspaceFromJSONTyped;
function DetailedWorkspaceToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'address_id': value['address_id'],
        'created_at': value['created_at'],
        'customer': (0, EmbeddedCustomer_1.EmbeddedCustomerToJSON)(value['customer']),
        'deleted_at': value['deleted_at'],
        'id': value['id'],
        'logo_id': value['logo_id'],
        'name': value['name'],
        'professional_id': value['professional_id'],
        'sits': (value['sits'].map(Sit_1.SitToJSON)),
        'subscription': (0, EmbeddedSubscription_1.EmbeddedSubscriptionToJSON)(value['subscription']),
        'updated_at': value['updated_at'],
    };
}
exports.DetailedWorkspaceToJSON = DetailedWorkspaceToJSON;
