import { CopyOutlined } from "@ant-design/icons";
import { Button, Flex, Timeline, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { copyToClipboard } from "../../../../../../functions/copyToClipboard";
import {
  ReportTemplateShortcut,
  ReportTemplateShortcutOutput,
} from "@thiana/api-thiana-client";
import { apiTemplateReports } from "../../../../../../api-configuration/Configuration";
import Context from "../../../../../../context/Context";
import { useNavigate, useParams } from "react-router-dom";
import { DEFAULT_TEMPLATE_UUID } from "../../../../../../utils/constants";

export default function Shortcuts() {
  const [shortcuts, setShortcuts] = useState<ReportTemplateShortcut[]>([]);
  const { currentReport } = useContext(Context);
  let navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    console.log("triggered");
    console.log(currentReport);
    if (currentReport?.report_template_id)
      getShortcuts(currentReport?.report_template_id);
    else getShortcuts(DEFAULT_TEMPLATE_UUID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport?.report_template_id]);

  // Fonction pour récupérer les raccourcis en base de données
  const getShortcuts = async (templateID: string) => {
    // Appel à l'API pour récupérer les raccourcis
    const response = await apiTemplateReports.templateReportShortcutGetAllRaw({
      templateId: templateID,
      equal: "report_template_id:" + templateID,
      items: 999,
      page: 1,
    });
    if (response.raw.status === 200) {
      let shortcutsResponse: ReportTemplateShortcutOutput =
        await response.raw.json();
      if (shortcutsResponse.data) setShortcuts(shortcutsResponse.data);
      else setShortcuts([]);
    }
  };

  return (
    <Flex
      vertical
      gap={8}
      style={{
        padding: 8,
        // height: "calc(100vh - 175px)",
        height: "100%",
        overflowY: "scroll",
      }}
    >
      {
        shortcuts?.length > 0 ? (
          shortcuts?.map((shortcut: ReportTemplateShortcut, index: number) => (
            <ShortcutItem
              key={index}
              title={shortcut.title}
              value={shortcut.content}
            />
          ))
        ) : (
          <div style={{ textAlign: "center" }}>
            <Button
              type={"link"}
              onClick={() => {
                navigate("/templates/" + currentReport?.report_template_id);
              }}
              size="small"
              style={{ fontSize: 12 }}
            >
              Vous pouvez créer des raccourcis pour ce modèle ici.
            </Button>
          </div>
        )
        //     : (
        //   <Typography style={{ fontSize: 12, opacity: 0.3, textAlign: "center" }}>
        //     Vous devez sélectionner un modèle pour utiliser les raccourcis.
        //   </Typography>
        // )
      }
    </Flex>
  );
}

interface Props {
  title: string;
  value: string;
}

function ShortcutItem(props: Props) {
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const { theme } = useContext(Context);

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData("text/plain", props.value);
  };

  return (
    <Flex
      vertical
      style={{
        border:
          theme === "light"
            ? "1px solid rgba(0,0,0,0.1)"
            : "1px solid rgba(255,255,255,0.1)",
        padding: 16,
        borderRadius: 16,
        boxSizing: "border-box",
      }}
    >
      <Flex
        style={{
          boxSizing: "border-box",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography style={{ fontWeight: "bold" }}>{props.title}</Typography>
        <Button
          type="text"
          icon={<CopyOutlined />}
          onClick={() => copyToClipboard(props.value)}
        ></Button>
      </Flex>
      <Typography
        style={{
          background: isHovered
            ? theme === "light"
              ? "rgba(0,0,0,0.2)"
              : "rgba(255,255,255,0.2)"
            : "transparent",
          padding: 8,
          borderRadius: 8,
          marginTop: 4,

          cursor: isClicked ? "grabbing" : "grab",
        }}
        draggable
        onDragStart={handleDragStart}
        onMouseDown={() => setIsClicked(true)}
        onMouseUp={() => setIsClicked(false)}
        onMouseLeave={() => {
          setIsClicked(false);
          setIsHovered(false);
        }}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
      >
        {props.value}
      </Typography>
    </Flex>
  );
}
