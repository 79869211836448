"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionInputToJSON = exports.SessionInputFromJSONTyped = exports.SessionInputFromJSON = exports.instanceOfSessionInput = void 0;
var SessionItem_1 = require("./SessionItem");
/**
 * Check if a given object implements the SessionInput interface.
 */
function instanceOfSessionInput(value) {
    if (!('items' in value) || value['items'] === undefined)
        return false;
    return true;
}
exports.instanceOfSessionInput = instanceOfSessionInput;
function SessionInputFromJSON(json) {
    return SessionInputFromJSONTyped(json, false);
}
exports.SessionInputFromJSON = SessionInputFromJSON;
function SessionInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'items': (json['items'].map(SessionItem_1.SessionItemFromJSON)),
    };
}
exports.SessionInputFromJSONTyped = SessionInputFromJSONTyped;
function SessionInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'items': (value['items'].map(SessionItem_1.SessionItemToJSON)),
    };
}
exports.SessionInputToJSON = SessionInputToJSON;
