"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CIM10OutputToJSON = exports.CIM10OutputFromJSONTyped = exports.CIM10OutputFromJSON = exports.instanceOfCIM10Output = void 0;
var Entry_1 = require("./Entry");
var Metadata_1 = require("./Metadata");
/**
 * Check if a given object implements the CIM10Output interface.
 */
function instanceOfCIM10Output(value) {
    if (!('data' in value) || value['data'] === undefined)
        return false;
    if (!('metadata' in value) || value['metadata'] === undefined)
        return false;
    return true;
}
exports.instanceOfCIM10Output = instanceOfCIM10Output;
function CIM10OutputFromJSON(json) {
    return CIM10OutputFromJSONTyped(json, false);
}
exports.CIM10OutputFromJSON = CIM10OutputFromJSON;
function CIM10OutputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': (json['data'].map(Entry_1.EntryFromJSON)),
        'metadata': (0, Metadata_1.MetadataFromJSON)(json['metadata']),
    };
}
exports.CIM10OutputFromJSONTyped = CIM10OutputFromJSONTyped;
function CIM10OutputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'data': (value['data'].map(Entry_1.EntryToJSON)),
        'metadata': (0, Metadata_1.MetadataToJSON)(value['metadata']),
    };
}
exports.CIM10OutputToJSON = CIM10OutputToJSON;
