import { useState } from "react";
import {
  DeleteOutlined,
  ExclamationCircleFilled,
  FileTextOutlined,
} from "@ant-design/icons";
import { Button, Flex, Modal, Typography, notification, theme } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { Template } from "../../../../types/Templates";
import { deleteTemplateById } from "../../../../api/api";

interface Props {
  index: number;
  isSelected: boolean;
  template: Template;
  templates: Template[];
  setTemplates: React.Dispatch<React.SetStateAction<Template[]>>;
  setMode: React.Dispatch<React.SetStateAction<"none" | "create" | "modify">>;
}

export default function ItemTemplate(props: Props) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);
  let navigate = useNavigate();
  const { token } = theme.useToken();

  const deleteTemplate = async (id: string, index: number) => {
    const response = await deleteTemplateById(id);
    let previousID = "";
    if (response.status === 204) {
      // On récupère l'id du template précédent
      if (index > 0) previousID = props.templates[index - 1].id;
      else props.setMode("none");
      // On retire le template supprimé de la liste
      let tmpTemplates = [...props.templates];
      tmpTemplates.splice(index, 1);
      // On met à jour la liste
      props.setTemplates(tmpTemplates);
      // On redirige l'utilisateur vers un autre item de la liste (le précédent)
      navigate("/templates/" + previousID);
      // Notification pour prévenir de la suppression
      notification.success({
        message: "Suppression réussie",
        description: "Le modèle a bien été supprimé.",
        duration: 3,
      });
    }
    setIsModalOpen(false);
  };

  return (
    <Flex align={"center"}>
      <Flex
        flex={1}
        justify="space-between"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        style={{
          backgroundColor:
            isHover || props.isSelected ? token.colorBgContainer : "",
          padding: 8,
          borderRadius: 8,
          cursor: "pointer",
        }}
        onClick={() => {
          navigate("/templates/" + props.template.id);
        }}
      >
        <Flex gap={"small"} align="left">
          <FileTextOutlined />
          <Typography style={{ flex: 1, fontSize: 16, textAlign: "left" }}>
            {props.template.title
              ? props.template.title.length > 20
                ? props.template.title.slice(0, 20) + "..."
                : props.template.title
              : "Modèle sans titre"}
          </Typography>
        </Flex>

        {props.isSelected ? (
          <>
            <Button
              size="small"
              style={{ width: 32 }}
              onClick={() => setIsModalOpen(true)}
              type="text"
              icon={<DeleteOutlined />}
            ></Button>
            <Modal
              title={
                <>
                  <ExclamationCircleFilled
                    style={{
                      height: 32,
                      width: 32,
                      fontSize: 22,
                      color: "#ff4d4f",
                    }}
                  />
                  Êtes-vous sûr de vouloir supprimer ce modèle ?
                </>
              }
              open={isModalOpen}
              onCancel={() => setIsModalOpen(false)}
              footer={[
                <Button
                  key="back"
                  type="default"
                  onClick={() => setIsModalOpen(false)}
                >
                  Annuler
                </Button>,
                <Button
                  onClick={async () => {
                    await deleteTemplate(
                      props.template.parent_id
                        ? props.template.parent_id
                        : props.template.id,
                      props.index
                    );
                    setIsModalOpen(false);
                  }}
                  type="primary"
                  danger
                  key="submit"
                >
                  Supprimer
                </Button>,
              ]}
            >
              <p>
                Une fois supprimé, le modèle sera définitivement perdu.
                Êtes-vous sur de vouloir continuer ?
              </p>
              <p>Si non, cliquez sur le bouton annuler.</p>
            </Modal>
          </>
        ) : (
          <div style={{ width: 32 }}></div>
        )}
      </Flex>
    </Flex>
  );
}
