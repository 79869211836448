import { Divider, Flex, theme, TourProps, Typography } from "antd";
import { useRef, useState } from "react";
import { Image } from "antd";
import welcomeSVG from "../assets/images/logo/LOGO_HEAD.svg";
import newdocSVG from "../assets/tour/2newdoc.svg";
import transcriptionSVG from "../assets/tour/3transcription.svg";
import titleSVG from "../assets/tour/4titre.svg";
import generationSVG from "../assets/tour/6generation.svg";
import hdsSVG from "../assets/tour/hds.png";
import {
  EyeOutlined,
  FileOutlined,
  FileTextFilled,
  StarOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faFile,
  faFileText,
  faMagicWandSparkles,
  faMicrophone,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

interface Props {
  updateOpenTour: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function useTour(props: Props) {
  const { token } = theme.useToken();

  const ref0 = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);
  const ref10 = useRef(null);
  const [stepsRef, setStepsRef] = useState<React.MutableRefObject<null>[]>([
    ref0,
    ref1,
    ref2,
    ref3,
    ref4,
    ref5,
    ref6,
    ref7,
    ref8,
    ref9,
    ref10,
  ]);

  const steps: TourProps["steps"] = [
    {
      title: "",
      description: (
        <Flex align={"center"} justify="space-evenly" gap={16}>
          <Flex vertical>
            <Typography.Text style={{ fontSize: 16, fontWeight: "bold" }}>
              Bienvenue sur Thiana Assistant Intelligent !
            </Typography.Text>
            <Typography.Text style={{ fontSize: 16 }}>
              Prêt à gagner du temps dans votre pratique ?
            </Typography.Text>
          </Flex>

          <Image width={132} src={welcomeSVG} alt="bienvenue" preview={false} />
        </Flex>
      ),

      nextButtonProps: { children: <>Oui, c'est parti !</> },
      target: () => ref0.current,
    },
    {
      title: "",
      description: (
        <Flex gap={"large"} align={"center"} style={{ margin: 20 }}>
          <Image
            width={40}
            src={newdocSVG}
            alt="new-document"
            preview={false}
          />
          <div>
            <b>Nouveau document</b>
            <br></br>
            Pour créer un nouveau document, cliquez sur ce bouton.
          </div>
        </Flex>
      ),
      nextButtonProps: { children: <div>Suivant</div> },
      prevButtonProps: { children: <div>Précédent</div> },
      target: () => ref1.current,
    },
    {
      title: "",
      description: (
        <Flex gap={16} align="center" justify="space-evenly">
          <Flex vertical gap={4}>
            <Typography>
              <b>Gérer vos modèles </b>
            </Typography>
            <Typography style={{ textAlign: "justify" }}>
              Vous pouvez créer vos modèles de compte rendu ici. Vous pouvez
              créer des modèles de compte-rendu ici afin de les utilisez par la
              suite.
            </Typography>
          </Flex>

          <FontAwesomeIcon
            icon={faFileText}
            style={{ height: 50, color: token.colorPrimary }}
          />
        </Flex>
      ),
      nextButtonProps: { children: <div>Suivant</div> },
      prevButtonProps: { children: <div>Précédent</div> },
      target: () => ref3.current,
      placement: "right",
    },
    {
      title: "",
      description: (
        <Flex style={{ margin: 20 }} gap={"small"}>
          <Typography>
            <b>Lancer la transcription</b>
            <br />
            Avant de commencer à parler, cliquer sur ce bouton, la transcription
            commencera !
          </Typography>
          <FontAwesomeIcon
            icon={faMicrophone}
            style={{ height: 50, color: token.colorPrimary }}
          />
        </Flex>
      ),
      nextButtonProps: { children: <div>Suivant</div> },
      prevButtonProps: { children: <div>Précédent</div> },
      target: () => ref4.current,
    },
    {
      title: "",
      description: (
        <Flex style={{ margin: 20 }} gap={"small"}>
          <Typography>
            <b>Transcription...</b>
            <br />
            C’est ici que Thiana retranscrit mot pour mot, ce qu’elle entend.
            Vous pouvez, si vous le souhaitez, relire et corriger ce qui a été
            transcrit.
          </Typography>
          <FontAwesomeIcon
            icon={faEye}
            style={{ height: 50, color: token.colorPrimary }}
          />
        </Flex>
      ),
      nextButtonProps: { children: <div>Suivant</div> },
      prevButtonProps: { children: <div>Précédent</div> },
      target: () => ref5.current,
      placement: "left",
    },
    {
      title: "",
      description: (
        <Flex style={{ margin: 20 }} gap={"large"} align="center">
          <Flex vertical>
            <Typography>
              <b>Générer le document</b>
            </Typography>
            <Typography style={{ textAlign: "justify" }}>
              A la fin de la consultation, cliquez sur le bouton "générer" et
              Thiana générera votre compte rendu à partir de la conversation.
            </Typography>
          </Flex>
          <FontAwesomeIcon
            icon={faMagicWandSparkles}
            style={{ height: 50, color: token.colorPrimary }}
          />{" "}
        </Flex>
      ),
      nextButtonProps: { children: <div>Suivant</div> },
      prevButtonProps: { children: <div>Précédent</div> },
      target: () => ref7.current,
      placement: "left",
    },
    {
      title: "",
      description: (
        <Flex style={{ margin: 20 }} gap={"large"} align="center">
          <Typography>
            <b>Relire ou modifier le document</b>
            <br />
            Une fois généré, vous pouvez relire et modifer le document à votre
            guise.
          </Typography>
          <FontAwesomeIcon
            icon={faEye}
            style={{ height: 50, color: token.colorPrimary }}
          />
        </Flex>
      ),
      target: () => ref8.current,
      nextButtonProps: { children: <div>Suivant</div> },
      prevButtonProps: { children: <div>Précédent</div> },
      placement: "left",
    },
    {
      title: "",
      description: (
        <Flex style={{ margin: 20 }} gap={"small"} align="center">
          <Typography>
            <b>Renseigner le titre</b>
            <br />
            Vous pouvez renseigner un titre à vos documents pour les retrouver
            plus facilement.
          </Typography>
          <FontAwesomeIcon
            icon={faEdit}
            style={{ height: 50, color: token.colorPrimary }}
          />
        </Flex>
      ),
      nextButtonProps: { children: <div>Suivant</div> },
      prevButtonProps: { children: <div>Précédent</div> },
      target: () => ref6.current,
      placement: "left",
    },
    {
      title: "",
      description: (
        <Flex style={{ margin: 20 }} gap={"large"} align="center">
          <Flex vertical gap={"small"} align="center" style={{ flex: 1 }}>
            <FontAwesomeIcon
              icon={faFile}
              style={{ height: 50, color: token.colorPrimary }}
            />
            <Typography>
              <b>Retrouver vos documents</b>
            </Typography>
            <Typography style={{ textAlign: "justify" }}>
              C’est ici que vous retrouverez tous vos documents enregistrés
              automatiquement.
            </Typography>
          </Flex>
          <Divider type="vertical" style={{ height: 400 }}></Divider>
          <Flex vertical style={{ flex: 2 }} align="center" gap={"small"}>
            <Image width={100} src={hdsSVG} alt="title" preview={false} />
            <Typography>
              <b>Thiana est 100 % français</b>
            </Typography>
            <Typography style={{ textAlign: "justify" }}>
              Les données de vos patients sont sensibles. Tous nos modèles sont
              hébergés sur des serveurs privés, en France, certifiés HDS
              (hébergeur de données de santé). Nous n'envoyons aucune donnée à
              aucune entreprise tierce, et encore moins à des GAFAM.
            </Typography>
          </Flex>
        </Flex>
      ),

      target: () => ref9.current,
      nextButtonProps: { children: <div>Suivant</div> },
      prevButtonProps: { children: <div>Précédent</div> },
      placement: "right",
    },
    {
      title: "",
      description: (
        <Flex style={{ margin: 20 }} gap={"small"} align="center">
          <Typography>
            <b>Bonus</b>
            <br />
            Vous pouvez donner votre avis sur l'observation générée par Thiana
            directement ici. Vos retours nous sont d'une grande aide pour
            améliorer l'expérience et la qualité de notre application !
          </Typography>

          <FontAwesomeIcon
            icon={faStar}
            style={{ height: 50, color: "yellow" }}
          />
        </Flex>
      ),

      target: () => ref10.current,
      nextButtonProps: { children: <div>Suivant</div> },
      prevButtonProps: { children: <div>Précédent</div> },
    },
    {
      title: "",
      description: (
        <Flex vertical style={{ margin: 20 }} gap={"small"} align="center">
          <img src={welcomeSVG} height={128} />
          <Typography>
            <b>Merci de votre attention !</b>
            <br />
          </Typography>
          <span style={{ textAlign: "center" }}>
            Si vous avez la moindre question, nous sommes à votre disposition
            via le chat en bas à droite de l'écran.
            <br />
            <br />
            L’équipe Thiana
          </span>
        </Flex>
      ),
      nextButtonProps: { children: <div>Terminer</div> },
      prevButtonProps: { children: <div>Précédent</div> },
    },
  ];

  return { stepsRef, setStepsRef, steps };
}
