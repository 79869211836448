import { Button, Flex, Tag, Divider } from "antd";
import React, { useContext } from "react";
import { PaymentsApi } from "@thiana/api-thiana-client";
import { configuration } from "../../../../api-configuration/Configuration";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";

interface Props {
  isMonthly: boolean;
  priceID: string;
  style: object;
  type: "essentielle" | "intégrale" | "entreprise";
}

export default function PlanInfos(props: Props) {
  const paymentsAPI = new PaymentsApi(configuration);

  const getCheckoutURL = async () => {
    const data = await paymentsAPI.sessionCreate({
      sessionInput: {
        items: [{ price_id: props.priceID, quantity: 1 }],
      },
    });
    window.open(data.data[0].url);
  };

  const titleStyle = { fontSize: "20px", fontWeight: 700 };
  return (
    <Flex
      flex={1}
      align="center"
      vertical
      style={{
        ...props.style,
        color: props.type === "essentielle" ? "black" : "white",
      }}
      gap={8}
    >
      <span style={titleStyle}>Essentielle</span>
      <span style={{ textAlign: "center" }}>
        {props.type === "essentielle" ? (
          <>
            Pour les consultations de médecine
            <br /> générale
          </>
        ) : props.type === "intégrale" ? (
          <>
            Pour les médecins rédigeant beaucoup de
            <br /> comtpes rendus
          </>
        ) : (
          <>
            Pour les groupements de plus de
            <br /> 5 médecins
          </>
        )}
      </span>
      <Flex vertical>
        {props.isMonthly ? (
          <></>
        ) : props.type === "entreprise" ? (
          <br />
        ) : (
          <Tag color="warning">Economisez 2 mois !</Tag>
        )}
        <Flex align="end">
          <span
            style={{
              fontSize: 36,
              fontWeight: 700,
              color: "#52C41A",
            }}
          >
            {props.type === "essentielle"
              ? props.isMonthly
                ? "39€"
                : "390€"
              : props.type === "intégrale"
              ? props.isMonthly
                ? "59€"
                : "590€"
              : "Offre"}
          </span>
          <span
            style={{
              fontSize: 18,
              fontWeight: 700,
              color: "#52C41A",
            }}
          >
            {props.type === "entreprise"
              ? "personnalisée"
              : props.isMonthly
              ? "/mois"
              : "/an"}
          </span>
        </Flex>
      </Flex>
      {props.type !== "entreprise" ? (
        <Button
          type={props.type === "essentielle" ? "primary" : "default"}
          onClick={() => getCheckoutURL()}
        >
          S'abonner
        </Button>
      ) : (
        <Button onClick={() => window.open("https://www.thiana.fr/contact")}>
          Contactez-nous
        </Button>
      )}
      <Divider
        style={{ backgroundColor: props.type !== "essentielle" ? "white" : "" }}
      />
      <Flex
        vertical
        justify="start"
        gap={8}
        style={{ height: "100%", width: "100%" }}
      >
        <span style={{ fontWeight: 700 }}>FONCTIONNALITÉS</span>
        <span>
          <FontAwesomeIcon icon={faCheck} /> Consultations classiques illimitées
        </span>
        <span>
          <FontAwesomeIcon icon={faCheck} /> Téléconsultations illimitées
        </span>
        <span>
          <FontAwesomeIcon icon={faCheck} /> Éditeur riche de texte
        </span>
        <span style={{ color: props.type === "essentielle" ? "gray" : "" }}>
          <FontAwesomeIcon
            icon={props.type === "essentielle" ? faX : faCheck}
          />{" "}
          Modifications par IA post génération
        </span>
        <span>
          <FontAwesomeIcon icon={faCheck} /> Raccourcis texte
        </span>
        <span style={{ fontWeight: 700 }}>PERSONNALISATION</span>
        <span>
          <FontAwesomeIcon icon={faCheck} /> Accès aux modèles types de Thiana
          Assistant
        </span>
        <span>
          <FontAwesomeIcon icon={faCheck} /> Personnalisation des raccourcis
          texte
        </span>
        <span style={{ color: props.type === "essentielle" ? "gray" : "" }}>
          <FontAwesomeIcon
            icon={props.type === "essentielle" ? faX : faCheck}
          />{" "}
          Création de modèles personnalisés
        </span>
        <span style={{ fontWeight: 700 }}>GÉNÉRALITÉS</span>
        <span>
          <FontAwesomeIcon icon={faCheck} /> Support technique via le chat
          depuis l'application
        </span>
        <span>
          <FontAwesomeIcon icon={faCheck} /> Stockage des données chez Thiana,
          accessible de partout
        </span>
      </Flex>
    </Flex>
  );
}
