"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TranscriptionStreamOutputToJSON = exports.TranscriptionStreamOutputFromJSONTyped = exports.TranscriptionStreamOutputFromJSON = exports.instanceOfTranscriptionStreamOutput = void 0;
/**
 * Check if a given object implements the TranscriptionStreamOutput interface.
 */
function instanceOfTranscriptionStreamOutput(value) {
    if (!('fully_validated' in value) || value['fully_validated'] === undefined)
        return false;
    if (!('in_validation' in value) || value['in_validation'] === undefined)
        return false;
    if (!('state' in value) || value['state'] === undefined)
        return false;
    if (!('transcription' in value) || value['transcription'] === undefined)
        return false;
    if (!('transcription_id' in value) || value['transcription_id'] === undefined)
        return false;
    if (!('validated' in value) || value['validated'] === undefined)
        return false;
    return true;
}
exports.instanceOfTranscriptionStreamOutput = instanceOfTranscriptionStreamOutput;
function TranscriptionStreamOutputFromJSON(json) {
    return TranscriptionStreamOutputFromJSONTyped(json, false);
}
exports.TranscriptionStreamOutputFromJSON = TranscriptionStreamOutputFromJSON;
function TranscriptionStreamOutputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'fully_validated': json['fully_validated'],
        'in_validation': json['in_validation'],
        'state': json['state'],
        'transcription': json['transcription'],
        'transcription_id': json['transcription_id'],
        'validated': json['validated'],
    };
}
exports.TranscriptionStreamOutputFromJSONTyped = TranscriptionStreamOutputFromJSONTyped;
function TranscriptionStreamOutputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'fully_validated': value['fully_validated'],
        'in_validation': value['in_validation'],
        'state': value['state'],
        'transcription': value['transcription'],
        'transcription_id': value['transcription_id'],
        'validated': value['validated'],
    };
}
exports.TranscriptionStreamOutputToJSON = TranscriptionStreamOutputToJSON;
