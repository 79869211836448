"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionToJSON = exports.SubscriptionFromJSONTyped = exports.SubscriptionFromJSON = exports.instanceOfSubscription = void 0;
var SubscriptionItem_1 = require("./SubscriptionItem");
/**
 * Check if a given object implements the Subscription interface.
 */
function instanceOfSubscription(value) {
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('customer_id' in value) || value['customer_id'] === undefined)
        return false;
    if (!('external_id' in value) || value['external_id'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('items' in value) || value['items'] === undefined)
        return false;
    if (!('status' in value) || value['status'] === undefined)
        return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined)
        return false;
    return true;
}
exports.instanceOfSubscription = instanceOfSubscription;
function SubscriptionFromJSON(json) {
    return SubscriptionFromJSONTyped(json, false);
}
exports.SubscriptionFromJSON = SubscriptionFromJSON;
function SubscriptionFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'created_at': json['created_at'],
        'customer_id': json['customer_id'],
        'external_id': json['external_id'],
        'id': json['id'],
        'items': (json['items'].map(SubscriptionItem_1.SubscriptionItemFromJSON)),
        'status': json['status'],
        'updated_at': json['updated_at'],
    };
}
exports.SubscriptionFromJSONTyped = SubscriptionFromJSONTyped;
function SubscriptionToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'created_at': value['created_at'],
        'customer_id': value['customer_id'],
        'external_id': value['external_id'],
        'id': value['id'],
        'items': (value['items'].map(SubscriptionItem_1.SubscriptionItemToJSON)),
        'status': value['status'],
        'updated_at': value['updated_at'],
    };
}
exports.SubscriptionToJSON = SubscriptionToJSON;
