"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientInputToJSON = exports.PatientInputFromJSONTyped = exports.PatientInputFromJSON = exports.instanceOfPatientInput = void 0;
/**
 * Check if a given object implements the PatientInput interface.
 */
function instanceOfPatientInput(value) {
    if (!('ssn' in value) || value['ssn'] === undefined)
        return false;
    return true;
}
exports.instanceOfPatientInput = instanceOfPatientInput;
function PatientInputFromJSON(json) {
    return PatientInputFromJSONTyped(json, false);
}
exports.PatientInputFromJSON = PatientInputFromJSON;
function PatientInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'ssn': json['ssn'],
        'user_id': json['user_id'] == null ? undefined : json['user_id'],
    };
}
exports.PatientInputFromJSONTyped = PatientInputFromJSONTyped;
function PatientInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'ssn': value['ssn'],
        'user_id': value['user_id'],
    };
}
exports.PatientInputToJSON = PatientInputToJSON;
