"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModelFileToJSON = exports.ModelFileFromJSONTyped = exports.ModelFileFromJSON = exports.instanceOfModelFile = void 0;
/**
 * Check if a given object implements the ModelFile interface.
 */
function instanceOfModelFile(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    return true;
}
exports.instanceOfModelFile = instanceOfModelFile;
function ModelFileFromJSON(json) {
    return ModelFileFromJSONTyped(json, false);
}
exports.ModelFileFromJSON = ModelFileFromJSON;
function ModelFileFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
    };
}
exports.ModelFileFromJSONTyped = ModelFileFromJSONTyped;
function ModelFileToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
    };
}
exports.ModelFileToJSON = ModelFileToJSON;
