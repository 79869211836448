"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatbotItemToJSON = exports.ChatbotItemFromJSONTyped = exports.ChatbotItemFromJSON = exports.instanceOfChatbotItem = exports.ChatbotItemRoleEnum = void 0;
/**
 * @export
 */
exports.ChatbotItemRoleEnum = {
    User: 'user',
    Chatbots: 'chatbots'
};
/**
 * Check if a given object implements the ChatbotItem interface.
 */
function instanceOfChatbotItem(value) {
    if (!('content' in value) || value['content'] === undefined)
        return false;
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('role' in value) || value['role'] === undefined)
        return false;
    return true;
}
exports.instanceOfChatbotItem = instanceOfChatbotItem;
function ChatbotItemFromJSON(json) {
    return ChatbotItemFromJSONTyped(json, false);
}
exports.ChatbotItemFromJSON = ChatbotItemFromJSON;
function ChatbotItemFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'content': json['content'],
        'created_at': json['created_at'],
        'id': json['id'],
        'role': json['role'],
    };
}
exports.ChatbotItemFromJSONTyped = ChatbotItemFromJSONTyped;
function ChatbotItemToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'content': value['content'],
        'created_at': value['created_at'],
        'id': value['id'],
        'role': value['role'],
    };
}
exports.ChatbotItemToJSON = ChatbotItemToJSON;
