"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfessionalPartialInputToJSON = exports.ProfessionalPartialInputFromJSONTyped = exports.ProfessionalPartialInputFromJSON = exports.instanceOfProfessionalPartialInput = void 0;
/**
 * Check if a given object implements the ProfessionalPartialInput interface.
 */
function instanceOfProfessionalPartialInput(value) {
    return true;
}
exports.instanceOfProfessionalPartialInput = instanceOfProfessionalPartialInput;
function ProfessionalPartialInputFromJSON(json) {
    return ProfessionalPartialInputFromJSONTyped(json, false);
}
exports.ProfessionalPartialInputFromJSON = ProfessionalPartialInputFromJSON;
function ProfessionalPartialInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'avatar': json['avatar'] == null ? undefined : json['avatar'],
        'is_assistant_tutorial_displayed': json['is_assistant_tutorial_displayed'] == null ? undefined : json['is_assistant_tutorial_displayed'],
        'personal_workspace_id': json['personal_workspace_id'] == null ? undefined : json['personal_workspace_id'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'school_certificate': json['school_certificate'] == null ? undefined : json['school_certificate'],
        'user_id': json['user_id'] == null ? undefined : json['user_id'],
        'voice_usage_accepted': json['voice_usage_accepted'] == null ? undefined : json['voice_usage_accepted'],
    };
}
exports.ProfessionalPartialInputFromJSONTyped = ProfessionalPartialInputFromJSONTyped;
function ProfessionalPartialInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'avatar': value['avatar'],
        'is_assistant_tutorial_displayed': value['is_assistant_tutorial_displayed'],
        'personal_workspace_id': value['personal_workspace_id'],
        'phone': value['phone'],
        'school_certificate': value['school_certificate'],
        'user_id': value['user_id'],
        'voice_usage_accepted': value['voice_usage_accepted'],
    };
}
exports.ProfessionalPartialInputToJSON = ProfessionalPartialInputToJSON;
