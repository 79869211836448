"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SecretaryInputToJSON = exports.SecretaryInputFromJSONTyped = exports.SecretaryInputFromJSON = exports.instanceOfSecretaryInput = void 0;
/**
 * Check if a given object implements the SecretaryInput interface.
 */
function instanceOfSecretaryInput(value) {
    if (!('professional_id' in value) || value['professional_id'] === undefined)
        return false;
    return true;
}
exports.instanceOfSecretaryInput = instanceOfSecretaryInput;
function SecretaryInputFromJSON(json) {
    return SecretaryInputFromJSONTyped(json, false);
}
exports.SecretaryInputFromJSON = SecretaryInputFromJSON;
function SecretaryInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'professional_id': json['professional_id'],
    };
}
exports.SecretaryInputFromJSONTyped = SecretaryInputFromJSONTyped;
function SecretaryInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'professional_id': value['professional_id'],
    };
}
exports.SecretaryInputToJSON = SecretaryInputToJSON;
