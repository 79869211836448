"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportTemplateOutputToJSON = exports.ReportTemplateOutputFromJSONTyped = exports.ReportTemplateOutputFromJSON = exports.instanceOfReportTemplateOutput = void 0;
var ReportTemplate_1 = require("./ReportTemplate");
var Metadata_1 = require("./Metadata");
/**
 * Check if a given object implements the ReportTemplateOutput interface.
 */
function instanceOfReportTemplateOutput(value) {
    if (!('data' in value) || value['data'] === undefined)
        return false;
    return true;
}
exports.instanceOfReportTemplateOutput = instanceOfReportTemplateOutput;
function ReportTemplateOutputFromJSON(json) {
    return ReportTemplateOutputFromJSONTyped(json, false);
}
exports.ReportTemplateOutputFromJSON = ReportTemplateOutputFromJSON;
function ReportTemplateOutputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': (json['data'].map(ReportTemplate_1.ReportTemplateFromJSON)),
        'metadata': json['metadata'] == null ? undefined : (0, Metadata_1.MetadataFromJSON)(json['metadata']),
    };
}
exports.ReportTemplateOutputFromJSONTyped = ReportTemplateOutputFromJSONTyped;
function ReportTemplateOutputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'data': (value['data'].map(ReportTemplate_1.ReportTemplateToJSON)),
        'metadata': (0, Metadata_1.MetadataToJSON)(value['metadata']),
    };
}
exports.ReportTemplateOutputToJSON = ReportTemplateOutputToJSON;
