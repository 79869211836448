"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvitationToJSON = exports.InvitationFromJSONTyped = exports.InvitationFromJSON = exports.instanceOfInvitation = void 0;
/**
 * Check if a given object implements the Invitation interface.
 */
function instanceOfInvitation(value) {
    if (!('accepted_at' in value) || value['accepted_at'] === undefined)
        return false;
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('deleted_at' in value) || value['deleted_at'] === undefined)
        return false;
    if (!('email' in value) || value['email'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined)
        return false;
    if (!('workspace_id' in value) || value['workspace_id'] === undefined)
        return false;
    return true;
}
exports.instanceOfInvitation = instanceOfInvitation;
function InvitationFromJSON(json) {
    return InvitationFromJSONTyped(json, false);
}
exports.InvitationFromJSON = InvitationFromJSON;
function InvitationFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'accepted_at': json['accepted_at'],
        'created_at': json['created_at'],
        'deleted_at': json['deleted_at'],
        'email': json['email'],
        'id': json['id'],
        'updated_at': json['updated_at'],
        'workspace_id': json['workspace_id'],
    };
}
exports.InvitationFromJSONTyped = InvitationFromJSONTyped;
function InvitationToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'accepted_at': value['accepted_at'],
        'created_at': value['created_at'],
        'deleted_at': value['deleted_at'],
        'email': value['email'],
        'id': value['id'],
        'updated_at': value['updated_at'],
        'workspace_id': value['workspace_id'],
    };
}
exports.InvitationToJSON = InvitationToJSON;
