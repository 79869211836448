"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserToJSON = exports.UserFromJSONTyped = exports.UserFromJSON = exports.instanceOfUser = void 0;
/**
 * Check if a given object implements the User interface.
 */
function instanceOfUser(value) {
    if (!('birth_date' in value) || value['birth_date'] === undefined)
        return false;
    if (!('cgu_accepted_at' in value) || value['cgu_accepted_at'] === undefined)
        return false;
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('deleted_at' in value) || value['deleted_at'] === undefined)
        return false;
    if (!('email' in value) || value['email'] === undefined)
        return false;
    if (!('first_name' in value) || value['first_name'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('is_active' in value) || value['is_active'] === undefined)
        return false;
    if (!('last_name' in value) || value['last_name'] === undefined)
        return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined)
        return false;
    return true;
}
exports.instanceOfUser = instanceOfUser;
function UserFromJSON(json) {
    return UserFromJSONTyped(json, false);
}
exports.UserFromJSON = UserFromJSON;
function UserFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'birth_date': json['birth_date'],
        'cgu_accepted_at': json['cgu_accepted_at'],
        'created_at': json['created_at'],
        'deleted_at': json['deleted_at'],
        'email': json['email'],
        'first_name': json['first_name'],
        'id': json['id'],
        'is_active': json['is_active'],
        'last_name': json['last_name'],
        'professional_id': json['professional_id'] == null ? undefined : json['professional_id'],
        'updated_at': json['updated_at'],
    };
}
exports.UserFromJSONTyped = UserFromJSONTyped;
function UserToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'birth_date': value['birth_date'],
        'cgu_accepted_at': value['cgu_accepted_at'],
        'created_at': value['created_at'],
        'deleted_at': value['deleted_at'],
        'email': value['email'],
        'first_name': value['first_name'],
        'id': value['id'],
        'is_active': value['is_active'],
        'last_name': value['last_name'],
        'professional_id': value['professional_id'],
        'updated_at': value['updated_at'],
    };
}
exports.UserToJSON = UserToJSON;
