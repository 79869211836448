"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudentInputToJSON = exports.StudentInputFromJSONTyped = exports.StudentInputFromJSON = exports.instanceOfStudentInput = void 0;
/**
 * Check if a given object implements the StudentInput interface.
 */
function instanceOfStudentInput(value) {
    if (!('professional_id' in value) || value['professional_id'] === undefined)
        return false;
    return true;
}
exports.instanceOfStudentInput = instanceOfStudentInput;
function StudentInputFromJSON(json) {
    return StudentInputFromJSONTyped(json, false);
}
exports.StudentInputFromJSON = StudentInputFromJSON;
function StudentInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'professional_id': json['professional_id'],
        'speciality': json['speciality'] == null ? undefined : json['speciality'],
        'status': json['status'] == null ? undefined : json['status'],
        'year_cycle': json['year_cycle'] == null ? undefined : json['year_cycle'],
    };
}
exports.StudentInputFromJSONTyped = StudentInputFromJSONTyped;
function StudentInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'professional_id': value['professional_id'],
        'speciality': value['speciality'],
        'status': value['status'],
        'year_cycle': value['year_cycle'],
    };
}
exports.StudentInputToJSON = StudentInputToJSON;
