"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkspaceInputToJSON = exports.WorkspaceInputFromJSONTyped = exports.WorkspaceInputFromJSON = exports.instanceOfWorkspaceInput = void 0;
var SitInput_1 = require("./SitInput");
/**
 * Check if a given object implements the WorkspaceInput interface.
 */
function instanceOfWorkspaceInput(value) {
    if (!('professional_id' in value) || value['professional_id'] === undefined)
        return false;
    return true;
}
exports.instanceOfWorkspaceInput = instanceOfWorkspaceInput;
function WorkspaceInputFromJSON(json) {
    return WorkspaceInputFromJSONTyped(json, false);
}
exports.WorkspaceInputFromJSON = WorkspaceInputFromJSON;
function WorkspaceInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'address_id': json['address_id'] == null ? undefined : json['address_id'],
        'customer_id': json['customer_id'] == null ? undefined : json['customer_id'],
        'logo_id': json['logo_id'] == null ? undefined : json['logo_id'],
        'name': json['name'] == null ? undefined : json['name'],
        'professional_id': json['professional_id'],
        'sits': json['sits'] == null ? undefined : (json['sits'].map(SitInput_1.SitInputFromJSON)),
    };
}
exports.WorkspaceInputFromJSONTyped = WorkspaceInputFromJSONTyped;
function WorkspaceInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'address_id': value['address_id'],
        'customer_id': value['customer_id'],
        'logo_id': value['logo_id'],
        'name': value['name'],
        'professional_id': value['professional_id'],
        'sits': value['sits'] == null ? undefined : (value['sits'].map(SitInput_1.SitInputToJSON)),
    };
}
exports.WorkspaceInputToJSON = WorkspaceInputToJSON;
