"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPartialInputToJSON = exports.UserPartialInputFromJSONTyped = exports.UserPartialInputFromJSON = exports.instanceOfUserPartialInput = void 0;
/**
 * Check if a given object implements the UserPartialInput interface.
 */
function instanceOfUserPartialInput(value) {
    return true;
}
exports.instanceOfUserPartialInput = instanceOfUserPartialInput;
function UserPartialInputFromJSON(json) {
    return UserPartialInputFromJSONTyped(json, false);
}
exports.UserPartialInputFromJSON = UserPartialInputFromJSON;
function UserPartialInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'birth_date': json['birth_date'] == null ? undefined : json['birth_date'],
        'first_name': json['first_name'] == null ? undefined : json['first_name'],
        'last_name': json['last_name'] == null ? undefined : json['last_name'],
        'password': json['password'] == null ? undefined : json['password'],
    };
}
exports.UserPartialInputFromJSONTyped = UserPartialInputFromJSONTyped;
function UserPartialInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'birth_date': value['birth_date'],
        'first_name': value['first_name'],
        'last_name': value['last_name'],
        'password': value['password'],
    };
}
exports.UserPartialInputToJSON = UserPartialInputToJSON;
