"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrialInputToJSON = exports.TrialInputFromJSONTyped = exports.TrialInputFromJSON = exports.instanceOfTrialInput = void 0;
/**
 * Check if a given object implements the TrialInput interface.
 */
function instanceOfTrialInput(value) {
    if (!('customer_id' in value) || value['customer_id'] === undefined)
        return false;
    if (!('scope' in value) || value['scope'] === undefined)
        return false;
    return true;
}
exports.instanceOfTrialInput = instanceOfTrialInput;
function TrialInputFromJSON(json) {
    return TrialInputFromJSONTyped(json, false);
}
exports.TrialInputFromJSON = TrialInputFromJSON;
function TrialInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'customer_id': json['customer_id'],
        'scope': json['scope'],
    };
}
exports.TrialInputFromJSONTyped = TrialInputFromJSONTyped;
function TrialInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'customer_id': value['customer_id'],
        'scope': value['scope'],
    };
}
exports.TrialInputToJSON = TrialInputToJSON;
