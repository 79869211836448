import React, { useState, useContext } from "react";
import { $getRoot, $getSelection, LexicalEditor } from "lexical";
import { useEffect } from "react";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import Toolbar from "./plugins/Toolbar";
import LexicalTheme from "./themes/theme";
import { ListNode, ListItemNode } from "@lexical/list";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import "./style.css";
import { Flex } from "antd";
import { $generateHtmlFromNodes } from "@lexical/html";
import { useParams } from "react-router";
import CustomOnChangePlugin from "./plugins/CustomOnChangePlugin";
import Context from "../../../../context/Context";

function onError(error: Error) {
  console.error(error);
}

// function onChange(
//   editor: LexicalEditor,
//   mode: "report" | "template",
//   id: string
// ) {
//   editor.update(() => {
//     const htmlString = $generateHtmlFromNodes(editor, null);
//     console.log(mode, id, htmlString);
//   });
// }

// interface Props {
//   onChange: (value: string) => void;
// }

export default function Editor() {
  const [mode, setMode] = useState<"report" | "template">();
  const [id, setID] = useState<string>();
  const { theme } = useContext(Context);

  const initialConfig = {
    namespace: "MyEditor",
    LexicalTheme,
    onError,
    nodes: [ListNode, ListItemNode],
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <Flex
        vertical
        style={{
          flex: "0 1",
          height: window.location.pathname.includes("templates")
            ? "calc(100vh - 178px)"
            : "calc(100vh - 325px)",
          // border: "1px solid blue",
        }}
      >
        <Toolbar />
        <RichTextPlugin
          contentEditable={
            <ContentEditable
              className={
                theme === "light"
                  ? "contentEditableLight"
                  : "contentEditableDark"
              }
            />
          }
          placeholder={
            <p className="placeholder" onClick={(e) => e.stopPropagation()}>
              <i>L'éditeur est vide. Sélectionnez un modèle.</i>
            </p>
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <CustomOnChangePlugin />
        {/* <OnChangePlugin
          onChange={(EditorState, editor) =>
            onChange(editor, mode as "template" | "report", id as string)
          }
        /> */}
        <HistoryPlugin />
      </Flex>
    </LexicalComposer>
  );
}
