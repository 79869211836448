"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MedicalRecordToJSON = exports.MedicalRecordFromJSONTyped = exports.MedicalRecordFromJSON = exports.instanceOfMedicalRecord = void 0;
var MedicalHistory_1 = require("./MedicalHistory");
var Consultation_1 = require("./Consultation");
/**
 * Check if a given object implements the MedicalRecord interface.
 */
function instanceOfMedicalRecord(value) {
    if (!('address_id' in value) || value['address_id'] === undefined)
        return false;
    if (!('autonomy' in value) || value['autonomy'] === undefined)
        return false;
    if (!('birth_date' in value) || value['birth_date'] === undefined)
        return false;
    if (!('consultations' in value) || value['consultations'] === undefined)
        return false;
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('deleted_at' in value) || value['deleted_at'] === undefined)
        return false;
    if (!('first_name' in value) || value['first_name'] === undefined)
        return false;
    if (!('gender' in value) || value['gender'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('last_name' in value) || value['last_name'] === undefined)
        return false;
    if (!('marital_status' in value) || value['marital_status'] === undefined)
        return false;
    if (!('medical_histories' in value) || value['medical_histories'] === undefined)
        return false;
    if (!('number_of_children' in value) || value['number_of_children'] === undefined)
        return false;
    if (!('patient_id' in value) || value['patient_id'] === undefined)
        return false;
    if (!('phone' in value) || value['phone'] === undefined)
        return false;
    if (!('profile' in value) || value['profile'] === undefined)
        return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined)
        return false;
    if (!('workspace_id' in value) || value['workspace_id'] === undefined)
        return false;
    return true;
}
exports.instanceOfMedicalRecord = instanceOfMedicalRecord;
function MedicalRecordFromJSON(json) {
    return MedicalRecordFromJSONTyped(json, false);
}
exports.MedicalRecordFromJSON = MedicalRecordFromJSON;
function MedicalRecordFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'address_id': json['address_id'],
        'autonomy': json['autonomy'],
        'birth_date': json['birth_date'],
        'consultations': (json['consultations'].map(Consultation_1.ConsultationFromJSON)),
        'created_at': json['created_at'],
        'deleted_at': json['deleted_at'],
        'first_name': json['first_name'],
        'gender': json['gender'],
        'id': json['id'],
        'last_name': json['last_name'],
        'marital_status': json['marital_status'],
        'medical_histories': (json['medical_histories'].map(MedicalHistory_1.MedicalHistoryFromJSON)),
        'number_of_children': json['number_of_children'],
        'patient_id': json['patient_id'],
        'phone': json['phone'],
        'profile': json['profile'],
        'updated_at': json['updated_at'],
        'workspace_id': json['workspace_id'],
    };
}
exports.MedicalRecordFromJSONTyped = MedicalRecordFromJSONTyped;
function MedicalRecordToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'address_id': value['address_id'],
        'autonomy': value['autonomy'],
        'birth_date': value['birth_date'],
        'consultations': (value['consultations'].map(Consultation_1.ConsultationToJSON)),
        'created_at': value['created_at'],
        'deleted_at': value['deleted_at'],
        'first_name': value['first_name'],
        'gender': value['gender'],
        'id': value['id'],
        'last_name': value['last_name'],
        'marital_status': value['marital_status'],
        'medical_histories': (value['medical_histories'].map(MedicalHistory_1.MedicalHistoryToJSON)),
        'number_of_children': value['number_of_children'],
        'patient_id': value['patient_id'],
        'phone': value['phone'],
        'profile': value['profile'],
        'updated_at': value['updated_at'],
        'workspace_id': value['workspace_id'],
    };
}
exports.MedicalRecordToJSON = MedicalRecordToJSON;
