import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import {
  FileTextOutlined,
  PlusOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Button, Flex, notification, theme } from "antd";
import Context from "../../../context/Context";
import ItemReport from "./ItemReport";
import { ClassifiedReports } from "../../../types/Reports";
import classifyReports from "../../../functions/classifyReports";
import { useNavigate } from "react-router";
import useReports from "../../../hooks/useReports";
import { Template } from "../../../types/Templates";
import { useParams } from "react-router-dom";
import { apiStorages } from "../../../api-configuration/Configuration";
import Subscribe from "./Subscribe";
import Profile from "./Profile";
import { PostFileResponse } from "../../../types/Files";
import { Report as ThianaReport } from "@thiana/api-thiana-client";
import { ThianaPagination } from "../../../types/Pagination";
import VoiceRecognitionAPI from "../common/voice-recognition/VoiceRecognitionAPI";

interface Props {
  SOCKET_ASR: React.MutableRefObject<WebSocket | undefined>;
  templates: Template[];
  setTemplates: React.Dispatch<React.SetStateAction<Template[]>>;
  paginationReports: ThianaPagination;
  setPaginationReports: React.Dispatch<React.SetStateAction<ThianaPagination>>;
}

export default function SideMenu(props: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showSelectMany, setShowSelectMany] = useState<boolean>(false);
  const [selectedIDs, setSelectedIDs] = useState<string[]>([]);
  const [classifiedReports, setClassifiedReports] = useState<ClassifiedReports>(
    {}
  );
  let { reportID } = useParams();
  const { token } = theme.useToken();

  // Context
  const {
    updateFileAvatar,
    updateIsMenuHidden,
    isMenuHidden,
    isASRProcessing,
    professional,
    stepsRef,

    reports,
    voiceRecognitionAPI,
    dispatchFlow,
  } = useContext(Context);

  // Navigate
  const navigate = useNavigate();

  // useReports
  let { deleteManyReports, resetAppState } = useReports({
    paginationReports: props.paginationReports,
    setPaginationReports: props.setPaginationReports,
  });

  useEffect(() => {
    if (reports.length > 0) setClassifiedReports(classifyReports(reports));
    else setClassifiedReports({});
  }, [reports]);

  //TODO - Remplacer si possible cette requete par la requete de api-thiana-client (apiStorages)
  const postFile = async (url: string, file: File) => {
    const accessToken = localStorage.getItem("accessJWT");
    const refreshToken = localStorage.getItem("refreshJWT");
    return await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/octet-stream",
        Authorization: `Bearer ${accessToken}`,
        "X-Refresh-Token": `${refreshToken}`,
      },
      body: file,
    });
  };

  //TODO - Remplacer si possible cette requete par la requete de api-thiana-client (apiProfessional)
  const patchProfessional = async (url: string, avatarID: string) => {
    const accessToken = localStorage.getItem("accessJWT");
    const refreshToken = localStorage.getItem("refreshJWT");
    return await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "X-Refresh-Token": `${refreshToken}`,
      },
      body: JSON.stringify({ avatar: avatarID }),
    });
  };

  const handleAvatarChange = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files?.[0]) {
      let postFileResponse: PostFileResponse;
      //TODO - Se servir du client
      // let response = await apiStorages.createFileRaw({
      //   body: event.target.files?.[0],
      // });
      let response = await postFile(
        process.env.REACT_APP_URL_BACKAPP + "/storages/files",
        event.target.files?.[0]
      );
      if (response.status === 201) {
        postFileResponse = await response.json();
        let getFileResponse: any;
        let response2 = await apiStorages.getFileRaw({
          id: postFileResponse.data[0].id,
        });
        if (response2.raw.status === 200) {
          getFileResponse = await response2.raw.blob();
          updateFileAvatar(URL.createObjectURL(getFileResponse));
          // On met à jour l'avatar du professionnel
          let response3 = await patchProfessional(
            process.env.REACT_APP_URL_BACKAPP +
              "/users/professionals/" +
              professional?.id,
            postFileResponse.data[0].id
          );
          if (response3.status === 200)
            notification.success({
              message: "Succès",
              description: "Votre avatar a bien été mis à jour.",
            });
          else
            notification.error({
              message: "Erreur",
              description: "L'avatar n'a pas pu être traité.",
            });
        }
      }
    }
  };

  const deleteSelection = async () => {
    await deleteManyReports(selectedIDs);
    setSelectedIDs([]);
    setShowSelectMany(false);
    navigate("/documents");
  };

  const Actions = () => (
    <Flex justify="right">
      {showSelectMany ? (
        <Flex gap={4}>
          <Button
            type={"primary"}
            danger
            size="small"
            style={{
              fontSize: 10,
              margin: 0,
              height: 20,
              color: showSelectMany ? "white" : "#d3d3d3",
            }}
            onClick={() => {
              setShowSelectMany(false);
              setSelectedIDs([]);
            }}
          >
            Annuler
          </Button>
          <Button
            type="primary"
            size="small"
            style={{
              fontSize: 10,
              margin: 0,
              height: 20,
              color: showSelectMany ? "white" : "#d3d3d3",
            }}
            onClick={() => deleteSelection()}
          >
            Supprimer ({selectedIDs.length})
          </Button>
        </Flex>
      ) : (
        <Button
          type="text"
          size="small"
          style={{
            fontSize: 10,
            margin: 0,
            height: 20,
          }}
          onClick={() => setShowSelectMany(true)}
        >
          Sélectionner
        </Button>
      )}
    </Flex>
  );

  return (
    <Flex
      vertical
      style={{
        background: token.colorBgContainer,
        width: 320 - 32,
        padding: 16,
        gap: 8,
        flexBasis: 0,
        flexGrow: 1,
        flexShrink: 1,
        height: "100vh",
        boxSizing: "border-box",
      }}
    >
      <Flex gap={8}>
        <Button
          icon={<UnorderedListOutlined />}
          onClick={() => {
            updateIsMenuHidden(true);
          }}
        />

        <Button
          ref={stepsRef[1]}
          style={{ flex: 1 }}
          icon={<PlusOutlined />}
          disabled={isASRProcessing}
          onClick={() => {
            resetAppState();
          }}
          data-cy="action-new-observation"
        >
          Nouveau document
        </Button>
      </Flex>

      <Button
        ref={stepsRef[3]}
        disabled={isASRProcessing}
        onClick={() => {
          if (props.templates[0]) {
            navigate("/templates/" + props.templates[0].id);
          } else navigate("/templates/");
        }}
        icon={<FileTextOutlined />}
        data-cy="action-model"
      >
        Modèles
      </Button>
      {/* //TODO - Recherche des reports à faire, quand le back le permettra 
      <Search placeholder="Rechercher ..."></Search> */}
      <Flex
        ref={stepsRef[9]}
        flex={1}
        vertical
        style={{
          display: "flex",
          overflow: "auto",
          borderBottom: token.colorBorder,
        }}
        onScrollCapture={(e: any) => {
          if (
            e.target.scrollHeight - e.target.clientHeight - 1 <
              e.target.scrollTop &&
            props.paginationReports.currentPage <
              props.paginationReports.nbPages
          )
            props.setPaginationReports({
              ...props.paginationReports,
              currentPage: props.paginationReports.currentPage + 1,
            });
        }}
      >
        <div>
          {Object.entries(classifiedReports).map(
            ([timePeriod, reports], index: number) => {
              return (
                <div key={timePeriod} style={{ marginTop: index > 0 ? 12 : 0 }}>
                  <Flex align="center" justify="space-between">
                    <h5 style={{ margin: 4, color: "#9e9e9e" }}>
                      {timePeriod}
                    </h5>
                    {index === 0 ? <Actions /> : null}
                  </Flex>

                  <Flex vertical gap={4}>
                    {reports.map((report: ThianaReport) => (
                      <ItemReport
                        showSelectMany={showSelectMany}
                        selectedIDs={selectedIDs}
                        setSelectedIDs={setSelectedIDs}
                        key={report.id}
                        disabled={isASRProcessing}
                        onClick={() => {
                          if (isASRProcessing) {
                            dispatchFlow({ type: "COMMAND_PAUSE_ASR" });
                            voiceRecognitionAPI?.stopTranscription(() => {
                              dispatchFlow({ type: "SOCKET_ASR_STOP" });
                            });
                          }
                          navigate("/documents/" + report.id);
                        }}
                        isSelected={reportID === report.id}
                        report={report}
                      />
                    ))}
                  </Flex>
                </div>
              );
            }
          )}
        </div>
      </Flex>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
        }}
      >
        <Subscribe />
        <Profile fileInputRef={fileInputRef} />
        <input
          type="file"
          name="photo"
          onChange={handleAvatarChange}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
      </div>
    </Flex>
  );
}
