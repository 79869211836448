import { FlowStateType, ActionTypes } from "../types/CommandsStates";

//Define Action et State types
export type State = {
  currentState: FlowStateType;
  lastActionType: ActionTypes | "";
  isASRProcessing: boolean;
  isLLMProcessing: boolean;
  animatedLiveResponse: string;
  liveResponseValidationIndices: {
    inValidationIndex: number;
    validatedIndex: number;
  };
  fixedLiveResponse: string;
};
export type Action = { type: ActionTypes; payload?: any | undefined };

// Define the initial state
export const initialState: State = {
  currentState: "READY",
  lastActionType: "",
  isASRProcessing: false,
  fixedLiveResponse: "",
  animatedLiveResponse: "",
  liveResponseValidationIndices: { inValidationIndex: 0, validatedIndex: 0 },
  isLLMProcessing: false,
};

// Define reducer
export default function FlowReducer(state: State, action: Action): State {
  console.log("action.type", action.type);
  switch (action.type) {
    case "RESET_APP_STATE":
      return { ...initialState };
    case "SWITCH_REPORT":
      return { ...state };
    case "COMMAND_LAUNCH_ASR":
      return {
        ...state,
        currentState: "RECORDING",
        lastActionType: action.type,
        isASRProcessing: true,
      };
    case "COMMAND_PAUSE_ASR":
      return {
        ...state,
        currentState: "WAITING_FOR_LAST_ASR_FEEDBACK",
        lastActionType: action.type,
      };
    case "SOCKET_ASR_FEEDBACK":
      return {
        ...state,
        animatedLiveResponse: action.payload.animatedLiveResponse,
        fixedLiveResponse: action.payload.fixedLiveResponse,
        liveResponseValidationIndices: {
          inValidationIndex: action.payload.validationIndices.inValidationIndex,
          validatedIndex: action.payload.validationIndices.validatedIndex,
        },
      };
    case "SOCKET_ASR_STOP":
      return {
        ...state,
        currentState: "PAUSE",
        lastActionType: action.type,
        animatedLiveResponse: "",
        fixedLiveResponse: "",
        isASRProcessing: false,
      };

    case "COMMAND_LAUNCH_LLM":
      return {
        ...state,
        currentState: "GENERATING",
        lastActionType: action.type,
        isLLMProcessing: true,
      };
    case "SOCKET_LAUNCH_LLM":
      return {
        ...state,
        currentState: "GENERATING",
        lastActionType: action.type,
        isLLMProcessing: true,
      };
    case "SOCKET_LLM_FEEDBACK":
      return {
        ...state,
        currentState: "GENERATING",
        isLLMProcessing: true,
      };
    case "SOCKET_LLM_STOP":
      return {
        ...state,
        currentState: "FINISHED",
        isLLMProcessing: false,
      };
    case "COMMAND_ABORT_LLM":
      return {
        ...state,
        currentState: "WAITING_FOR_LAST_LLM_FEEDBACK",
        isLLMProcessing: false,
      };
    case "COMMAND_DELETE_CURRENT_DATA":
      return {
        ...state,
        currentState: "READY",
        lastActionType: action.type,
      };
  }
  return state;
}
