"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportOutputToJSON = exports.ReportOutputFromJSONTyped = exports.ReportOutputFromJSON = exports.instanceOfReportOutput = void 0;
var Report_1 = require("./Report");
var Metadata_1 = require("./Metadata");
/**
 * Check if a given object implements the ReportOutput interface.
 */
function instanceOfReportOutput(value) {
    if (!('data' in value) || value['data'] === undefined)
        return false;
    return true;
}
exports.instanceOfReportOutput = instanceOfReportOutput;
function ReportOutputFromJSON(json) {
    return ReportOutputFromJSONTyped(json, false);
}
exports.ReportOutputFromJSON = ReportOutputFromJSON;
function ReportOutputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': (json['data'].map(Report_1.ReportFromJSON)),
        'metadata': json['metadata'] == null ? undefined : (0, Metadata_1.MetadataFromJSON)(json['metadata']),
    };
}
exports.ReportOutputFromJSONTyped = ReportOutputFromJSONTyped;
function ReportOutputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'data': (value['data'].map(Report_1.ReportToJSON)),
        'metadata': (0, Metadata_1.MetadataToJSON)(value['metadata']),
    };
}
exports.ReportOutputToJSON = ReportOutputToJSON;
