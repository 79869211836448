"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatbotStreamToJSON = exports.ChatbotStreamFromJSONTyped = exports.ChatbotStreamFromJSON = exports.instanceOfChatbotStream = void 0;
/**
 * Check if a given object implements the ChatbotStream interface.
 */
function instanceOfChatbotStream(value) {
    if (!('is_completed' in value) || value['is_completed'] === undefined)
        return false;
    if (!('parameters' in value) || value['parameters'] === undefined)
        return false;
    if (!('tool_name' in value) || value['tool_name'] === undefined)
        return false;
    return true;
}
exports.instanceOfChatbotStream = instanceOfChatbotStream;
function ChatbotStreamFromJSON(json) {
    return ChatbotStreamFromJSONTyped(json, false);
}
exports.ChatbotStreamFromJSON = ChatbotStreamFromJSON;
function ChatbotStreamFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'is_completed': json['is_completed'],
        'parameters': json['parameters'],
        'tool_name': json['tool_name'],
    };
}
exports.ChatbotStreamFromJSONTyped = ChatbotStreamFromJSONTyped;
function ChatbotStreamToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'is_completed': value['is_completed'],
        'parameters': value['parameters'],
        'tool_name': value['tool_name'],
    };
}
exports.ChatbotStreamToJSON = ChatbotStreamToJSON;
