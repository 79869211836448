"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MedicalRecordInputToJSON = exports.MedicalRecordInputFromJSONTyped = exports.MedicalRecordInputFromJSON = exports.instanceOfMedicalRecordInput = void 0;
/**
 * Check if a given object implements the MedicalRecordInput interface.
 */
function instanceOfMedicalRecordInput(value) {
    if (!('patient_id' in value) || value['patient_id'] === undefined)
        return false;
    if (!('workspace_id' in value) || value['workspace_id'] === undefined)
        return false;
    return true;
}
exports.instanceOfMedicalRecordInput = instanceOfMedicalRecordInput;
function MedicalRecordInputFromJSON(json) {
    return MedicalRecordInputFromJSONTyped(json, false);
}
exports.MedicalRecordInputFromJSON = MedicalRecordInputFromJSON;
function MedicalRecordInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'address_id': json['address_id'] == null ? undefined : json['address_id'],
        'autonomy': json['autonomy'] == null ? undefined : json['autonomy'],
        'birth_date': json['birth_date'] == null ? undefined : json['birth_date'],
        'first_name': json['first_name'] == null ? undefined : json['first_name'],
        'gender': json['gender'] == null ? undefined : json['gender'],
        'last_name': json['last_name'] == null ? undefined : json['last_name'],
        'marital_status': json['marital_status'] == null ? undefined : json['marital_status'],
        'number_of_children': json['number_of_children'] == null ? undefined : json['number_of_children'],
        'patient_id': json['patient_id'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'profile': json['profile'] == null ? undefined : json['profile'],
        'workspace_id': json['workspace_id'],
    };
}
exports.MedicalRecordInputFromJSONTyped = MedicalRecordInputFromJSONTyped;
function MedicalRecordInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'address_id': value['address_id'],
        'autonomy': value['autonomy'],
        'birth_date': value['birth_date'],
        'first_name': value['first_name'],
        'gender': value['gender'],
        'last_name': value['last_name'],
        'marital_status': value['marital_status'],
        'number_of_children': value['number_of_children'],
        'patient_id': value['patient_id'],
        'phone': value['phone'],
        'profile': value['profile'],
        'workspace_id': value['workspace_id'],
    };
}
exports.MedicalRecordInputToJSON = MedicalRecordInputToJSON;
