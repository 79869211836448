"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcessingInputToJSON = exports.ProcessingInputFromJSONTyped = exports.ProcessingInputFromJSON = exports.instanceOfProcessingInput = void 0;
/**
 * Check if a given object implements the ProcessingInput interface.
 */
function instanceOfProcessingInput(value) {
    if (!('processingType' in value) || value['processingType'] === undefined)
        return false;
    if (!('report_id' in value) || value['report_id'] === undefined)
        return false;
    if (!('toBeCleaned' in value) || value['toBeCleaned'] === undefined)
        return false;
    if (!('transcription' in value) || value['transcription'] === undefined)
        return false;
    return true;
}
exports.instanceOfProcessingInput = instanceOfProcessingInput;
function ProcessingInputFromJSON(json) {
    return ProcessingInputFromJSONTyped(json, false);
}
exports.ProcessingInputFromJSON = ProcessingInputFromJSON;
function ProcessingInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'html_content': json['html_content'] == null ? undefined : json['html_content'],
        'processingType': json['processingType'],
        'raw_content': json['raw_content'] == null ? undefined : json['raw_content'],
        'report_id': json['report_id'],
        'toBeCleaned': json['toBeCleaned'],
        'transcription': json['transcription'],
    };
}
exports.ProcessingInputFromJSONTyped = ProcessingInputFromJSONTyped;
function ProcessingInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'html_content': value['html_content'],
        'processingType': value['processingType'],
        'raw_content': value['raw_content'],
        'report_id': value['report_id'],
        'toBeCleaned': value['toBeCleaned'],
        'transcription': value['transcription'],
    };
}
exports.ProcessingInputToJSON = ProcessingInputToJSON;
