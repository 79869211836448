"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailInputToJSON = exports.EmailInputFromJSONTyped = exports.EmailInputFromJSON = exports.instanceOfEmailInput = void 0;
/**
 * Check if a given object implements the EmailInput interface.
 */
function instanceOfEmailInput(value) {
    if (!('action' in value) || value['action'] === undefined)
        return false;
    if (!('content' in value) || value['content'] === undefined)
        return false;
    if (!('link' in value) || value['link'] === undefined)
        return false;
    if (!('pre_header' in value) || value['pre_header'] === undefined)
        return false;
    if (!('subject' in value) || value['subject'] === undefined)
        return false;
    if (!('to' in value) || value['to'] === undefined)
        return false;
    return true;
}
exports.instanceOfEmailInput = instanceOfEmailInput;
function EmailInputFromJSON(json) {
    return EmailInputFromJSONTyped(json, false);
}
exports.EmailInputFromJSON = EmailInputFromJSON;
function EmailInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'action': json['action'],
        'content': json['content'],
        'link': json['link'],
        'pre_header': json['pre_header'],
        'subject': json['subject'],
        'to': json['to'],
    };
}
exports.EmailInputFromJSONTyped = EmailInputFromJSONTyped;
function EmailInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'action': value['action'],
        'content': value['content'],
        'link': value['link'],
        'pre_header': value['pre_header'],
        'subject': value['subject'],
        'to': value['to'],
    };
}
exports.EmailInputToJSON = EmailInputToJSON;
