"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileOutputToJSON = exports.FileOutputFromJSONTyped = exports.FileOutputFromJSON = exports.instanceOfFileOutput = void 0;
/**
 * Check if a given object implements the FileOutput interface.
 */
function instanceOfFileOutput(value) {
    if (!('data' in value) || value['data'] === undefined)
        return false;
    return true;
}
exports.instanceOfFileOutput = instanceOfFileOutput;
function FileOutputFromJSON(json) {
    return FileOutputFromJSONTyped(json, false);
}
exports.FileOutputFromJSON = FileOutputFromJSON;
function FileOutputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': json['data'],
    };
}
exports.FileOutputFromJSONTyped = FileOutputFromJSONTyped;
function FileOutputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'data': value['data'],
    };
}
exports.FileOutputToJSON = FileOutputToJSON;
