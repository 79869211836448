"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfessionalToJSON = exports.ProfessionalFromJSONTyped = exports.ProfessionalFromJSON = exports.instanceOfProfessional = void 0;
var AdministrativeProof_1 = require("./AdministrativeProof");
/**
 * Check if a given object implements the Professional interface.
 */
function instanceOfProfessional(value) {
    if (!('administrative_proofs' in value) || value['administrative_proofs'] === undefined)
        return false;
    if (!('avatar' in value) || value['avatar'] === undefined)
        return false;
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('is_assistant_tutorial_displayed' in value) || value['is_assistant_tutorial_displayed'] === undefined)
        return false;
    if (!('personal_workspace_id' in value) || value['personal_workspace_id'] === undefined)
        return false;
    if (!('phone' in value) || value['phone'] === undefined)
        return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined)
        return false;
    if (!('user_id' in value) || value['user_id'] === undefined)
        return false;
    if (!('voice_usage_accepted_at' in value) || value['voice_usage_accepted_at'] === undefined)
        return false;
    return true;
}
exports.instanceOfProfessional = instanceOfProfessional;
function ProfessionalFromJSON(json) {
    return ProfessionalFromJSONTyped(json, false);
}
exports.ProfessionalFromJSON = ProfessionalFromJSON;
function ProfessionalFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'administrative_proofs': (json['administrative_proofs'].map(AdministrativeProof_1.AdministrativeProofFromJSON)),
        'avatar': json['avatar'],
        'created_at': json['created_at'],
        'deleted_at': json['deleted_at'] == null ? undefined : json['deleted_at'],
        'id': json['id'],
        'is_assistant_tutorial_displayed': json['is_assistant_tutorial_displayed'],
        'personal_workspace_id': json['personal_workspace_id'],
        'phone': json['phone'],
        'updated_at': json['updated_at'],
        'user_id': json['user_id'],
        'voice_usage_accepted_at': json['voice_usage_accepted_at'],
    };
}
exports.ProfessionalFromJSONTyped = ProfessionalFromJSONTyped;
function ProfessionalToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'administrative_proofs': (value['administrative_proofs'].map(AdministrativeProof_1.AdministrativeProofToJSON)),
        'avatar': value['avatar'],
        'created_at': value['created_at'],
        'deleted_at': value['deleted_at'],
        'id': value['id'],
        'is_assistant_tutorial_displayed': value['is_assistant_tutorial_displayed'],
        'personal_workspace_id': value['personal_workspace_id'],
        'phone': value['phone'],
        'updated_at': value['updated_at'],
        'user_id': value['user_id'],
        'voice_usage_accepted_at': value['voice_usage_accepted_at'],
    };
}
exports.ProfessionalToJSON = ProfessionalToJSON;
