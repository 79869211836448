"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneratedDocumentToJSON = exports.GeneratedDocumentFromJSONTyped = exports.GeneratedDocumentFromJSON = exports.instanceOfGeneratedDocument = void 0;
/**
 * Check if a given object implements the GeneratedDocument interface.
 */
function instanceOfGeneratedDocument(value) {
    if (!('content' in value) || value['content'] === undefined)
        return false;
    if (!('document_type' in value) || value['document_type'] === undefined)
        return false;
    if (!('is_completed' in value) || value['is_completed'] === undefined)
        return false;
    return true;
}
exports.instanceOfGeneratedDocument = instanceOfGeneratedDocument;
function GeneratedDocumentFromJSON(json) {
    return GeneratedDocumentFromJSONTyped(json, false);
}
exports.GeneratedDocumentFromJSON = GeneratedDocumentFromJSON;
function GeneratedDocumentFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'content': json['content'],
        'document_type': json['document_type'],
        'is_completed': json['is_completed'],
    };
}
exports.GeneratedDocumentFromJSONTyped = GeneratedDocumentFromJSONTyped;
function GeneratedDocumentToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'content': value['content'],
        'document_type': value['document_type'],
        'is_completed': value['is_completed'],
    };
}
exports.GeneratedDocumentToJSON = GeneratedDocumentToJSON;
