"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentToJSON = exports.DocumentFromJSONTyped = exports.DocumentFromJSON = exports.instanceOfDocument = void 0;
/**
 * Check if a given object implements the Document interface.
 */
function instanceOfDocument(value) {
    if (!('consultation_id' in value) || value['consultation_id'] === undefined)
        return false;
    if (!('content' in value) || value['content'] === undefined)
        return false;
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('created_by' in value) || value['created_by'] === undefined)
        return false;
    if (!('deleted_at' in value) || value['deleted_at'] === undefined)
        return false;
    if (!('description' in value) || value['description'] === undefined)
        return false;
    if (!('document_type' in value) || value['document_type'] === undefined)
        return false;
    if (!('file' in value) || value['file'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('medical_record_id' in value) || value['medical_record_id'] === undefined)
        return false;
    if (!('professional_id' in value) || value['professional_id'] === undefined)
        return false;
    if (!('title' in value) || value['title'] === undefined)
        return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined)
        return false;
    if (!('version' in value) || value['version'] === undefined)
        return false;
    return true;
}
exports.instanceOfDocument = instanceOfDocument;
function DocumentFromJSON(json) {
    return DocumentFromJSONTyped(json, false);
}
exports.DocumentFromJSON = DocumentFromJSON;
function DocumentFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'consultation_id': json['consultation_id'],
        'content': json['content'],
        'created_at': json['created_at'],
        'created_by': json['created_by'],
        'deleted_at': json['deleted_at'],
        'description': json['description'],
        'document_type': json['document_type'],
        'file': json['file'],
        'id': json['id'],
        'medical_record_id': json['medical_record_id'],
        'professional_id': json['professional_id'],
        'title': json['title'],
        'updated_at': json['updated_at'],
        'version': json['version'],
    };
}
exports.DocumentFromJSONTyped = DocumentFromJSONTyped;
function DocumentToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'consultation_id': value['consultation_id'],
        'content': value['content'],
        'created_at': value['created_at'],
        'created_by': value['created_by'],
        'deleted_at': value['deleted_at'],
        'description': value['description'],
        'document_type': value['document_type'],
        'file': value['file'],
        'id': value['id'],
        'medical_record_id': value['medical_record_id'],
        'professional_id': value['professional_id'],
        'title': value['title'],
        'updated_at': value['updated_at'],
        'version': value['version'],
    };
}
exports.DocumentToJSON = DocumentToJSON;
